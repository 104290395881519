import Header from '../common/Header';
import React, { useEffect } from 'react';
import { wx, init } from '../util/WxUtil';
import sm from '../icon/sm.svg';
import { Mask } from 'antd-mobile';

export default function Yzym() {
  const [visible, setVisible] = React.useState(false);
  const [url, setUrl] = React.useState('');
  useEffect(() => {
    init();
  }, []);
  return (
    <>
      <Header name={'一证一码'} />
      <div
        onClick={() => {
          setVisible(true);
          wx.ready(() => {
            wx.scanQRCode({
              needResult: 1,
              scanType: ['qrCode', 'barCode'],
              success: function (res: { resultStr: any }) {
                // const result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                // const url = res.resultStr.split('&')[0] || '';
                // setUrl(res.resultStr);
                window.location.href = res.resultStr;
              },
              fail: function (err: any) {
                setVisible(false);
              },
              cancel: function () {
                setVisible(false);
              },
            });
          });
        }}
        style={{ width: '100%', textAlign: 'center' }}
      >
        <div>
          <img src={sm} alt="" width={100} style={{ marginTop: '10vh' }} />
        </div>
        <div style={{ color: '#009FE8', fontSize: 16 }}>扫一扫</div>
      </div>
      <div>{url}</div>
      <Mask color="white" visible={visible} />
    </>
  );
}
