import Header from '../../common/Header';
import Footer from '../../common/Footer';
import React, { RefObject, useEffect } from 'react';
import {
  Tabs,
  Form,
  Input,
  Picker,
  Space,
  Button,
  DatePickerRef,
  DatePicker,
  Toast,
  DotLoading,
} from 'antd-mobile';
import dayjs from 'dayjs';
import { RightOutline, PhoneFill, EnvironmentOutline } from 'antd-mobile-icons';
import './Zxyy.css';
import { useState } from 'react';
import { request } from '../../util/request';
import { UserResponse } from '../../common/PersonalCenter';

interface Selector {
  label: string;
  value: string;
}

interface History {
  name: string; //预约人
  ywlx: string; //业务
  reservedate: string; //预约时间
  yyqy: string; //预约地区
  [key: string]: any;
}

interface Area {
  address: string;
  id: string;
  phone: string;
  pointA: string;
  pointB: string;
  title: string;
  label: string;
  value: string;
}

export default function Zxyy() {
  const [form] = Form.useForm();
  //用户信息
  const [user, setUser] = useState<UserResponse>();
  //业务列表
  const [businessList, setBusinessList] = useState<Selector[][]>([[]]);
  //选择的业务
  const [business, setBusiness] = useState<(string | number | null)[]>([]);
  //预约区域列表
  const [xzqhList, setXzqhList] = useState<Area[][]>([[]]);
  //选择的预约区域代码
  const [xzqh, setXzqh] = useState<(string | number | null)[]>([]);
  //预约详细信息
  const [areaInfo, setAreaInfo] = useState<Area>();
  //申请历史
  const [historyList, setHistoryList] = useState<History[]>([]);
  //是否正在提交数据
  const [loading, setLoading] = useState<Boolean>(false);

  //选择业务
  function onBusinessChange(value: (string | number | null)[]) {
    setBusiness(value);
    //需要手动设置值，表单组件无法同步Picker组件的数据
    form.setFieldValue('business', value);
    form.setFieldValue('name', user?.name);
    form.setFieldValue('idCard', user?.idCard);
    form.setFieldValue('phone', user?.phone);
  }

  //选择行政区划代码
  function onXzqhChange(value: (string | number | null)[]) {
    setXzqh(value);
    for (let i = 0; i < xzqhList[0].length; i++) {
      if (value[0] === xzqhList[0][i].id) {
        setAreaInfo(xzqhList[0][i]);
        break;
      }
    }
    //需要手动设置表单的xzqh值，表单组件无法同步Picker组件的数据
    form.setFieldValue('xzqh', value);
  }

  //提交预约
  function onSubmit(formData: {
    name: string;
    idCard: string;
    phone: string;
    business: string[];
    date: string;
    xzqh: string[];
  }) {
    setLoading(true);
    let qxdm: string | undefined = '';
    for (let i = 0; i < xzqhList[0].length; i++) {
      if (formData.xzqh[0] === xzqhList[0][i].value) {
        qxdm = xzqhList[0][i].label;
        break;
      }
    }
    request
      .post('/yyfw/addReserve', {
        ywlx: formData.business[0],
        reserveDate: dayjs(formData.date).format('YYYY-MM-DD'),
        qxdm: qxdm,
      })
      .then(() => {
        Toast.show({
          content: '提交成功！',
          icon: 'success',
        });
        //重置表单数据
        form.resetFields();
        setXzqh([]);
        setBusiness([]);
        //预约人信息要保留
        form.setFieldValue('name', user?.name);
        form.setFieldValue('idCard', user?.idCard);
        form.setFieldValue('phone', user?.phone);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  //标签页切换
  function onChangeTab(key: string) {
    if (key === '2') {
      request.get<History[]>('/yyfw/getReserveList').then((data) => {
        setHistoryList(data);
      });
    }
  }

  useEffect(() => {
    request.get<UserResponse>('/auth/user_info').then((data) => {
      setUser(data);
    });
    request.get<Area[]>('/addressInfo/list').then((data) => {
      for (const item of data) {
        item.label = item.title;
        item.value = item.id;
      }
      setXzqhList([data]);
    });
    request.get<Selector[]>('/yyfw/djlxlist').then((data) => {
      setBusinessList([data]);
    });
  }, []);
  return (
    <div className={'zxyy-page'}>
      <Header name={'在线预约'} />
      <Tabs onChange={onChangeTab}>
        <Tabs.Tab title="我要预约" key="1">
          <div className="reservation">
            <div
              style={{ display: loading ? 'flex' : 'none' }}
              className="loading"
            >
              <span>数据提交中</span>
              <DotLoading color="currentColor" />
            </div>
            <Form
              form={form}
              onFinish={onSubmit}
              footer={
                <Button block type="submit" color="primary" size="large">
                  预约
                </Button>
              }
            >
              <div className="block-title">预约人信息</div>
              <Form.Item
                label="姓名"
                layout="horizontal"
                name="name"
                rules={[{ required: true }]}
              >
                <div>{user?.name}</div>
              </Form.Item>
              <Form.Item
                label="身份证"
                layout="horizontal"
                name="idCard"
                rules={[{ required: true }]}
              >
                <div>{user?.idCard}</div>
              </Form.Item>
              <Form.Item
                label="电话号码"
                layout="horizontal"
                name="phone"
                rules={[{ required: true }]}
              >
                <div>{user?.phone}</div>
              </Form.Item>
              <div className="block-title">预约信息</div>
              <Form.Item
                name="business"
                label="预约业务"
                rules={[{ required: true, message: '请选择业务' }]}
                layout="horizontal"
              >
                <Picker
                  columns={businessList}
                  value={business}
                  onConfirm={onBusinessChange}
                >
                  {(items, { open }) => {
                    return (
                      <Space align="center" onClick={open}>
                        {items.every((item) => item === null)
                          ? '请选择业务'
                          : items
                              .map((item) => item?.label ?? '请选择业务')
                              .join('-')}
                        <div className="picker-item">
                          <RightOutline className="icon" />
                        </div>
                      </Space>
                    );
                  }}
                </Picker>
              </Form.Item>
              <Form.Item
                name="date"
                label="预约时间"
                trigger="onConfirm"
                rules={[{ required: true, message: '请选择日期' }]}
                layout="horizontal"
                onClick={(e, datePickerRef: RefObject<DatePickerRef>) => {
                  datePickerRef.current?.open();
                }}
              >
                <DatePicker>
                  {(value) =>
                    value ? dayjs(value).format('YYYY-MM-DD') : '请选择日期'
                  }
                </DatePicker>
              </Form.Item>
              <Form.Item
                name="xzqh"
                label="预约区域:"
                rules={[{ required: true, message: '请选择预约区域' }]}
                layout="horizontal"
              >
                <Picker
                  columns={xzqhList}
                  value={xzqh}
                  onConfirm={onXzqhChange}
                >
                  {(items, { open }) => {
                    return (
                      <Space align="center" onClick={open}>
                        {items.every((item) => item === null)
                          ? '请选择预约区域'
                          : items
                              .map((item) => item?.label ?? '请选择预约区域')
                              .join('-')}
                        <div className="picker-item">
                          <RightOutline className="icon" />
                        </div>
                      </Space>
                    );
                  }}
                </Picker>
              </Form.Item>
              <div
                className="xzq-info"
                style={{ display: xzqh.length ? 'block' : 'none' }}
              >
                <div className="department-name">{areaInfo?.title}</div>
                <div className="department-phone">
                  <div className="icon">
                    <PhoneFill />
                  </div>
                  <div className="value">{areaInfo?.phone}</div>
                </div>
                <div className="department-address">
                  <div className="icon">
                    <EnvironmentOutline />
                  </div>
                  <div className="value">{areaInfo?.address}</div>
                </div>
              </div>
            </Form>
          </div>
        </Tabs.Tab>
        <Tabs.Tab title="申请历史" key="2">
          <div className="history-list">
            {historyList.map((item, index) => {
              return (
                <div className="history-item" key={index}>
                  <div className="history-user">
                    <div className="label">预约人：</div>
                    <div className="value">{item.name}</div>
                  </div>
                  <div className="history-business">
                    <div className="label">预约业务：</div>
                    <div className="value">{item.ywlx}</div>
                  </div>
                  <div className="history-date">
                    <div className="label">预约时间：</div>
                    <div className="value">{item.reservedate}</div>
                  </div>
                  <div className="history-id">
                    <div className="label">证书编号：</div>
                    <div className="value">{item.zsbh}</div>
                  </div>
                  <div className="history-area">
                    <div className="label">预约区域：</div>
                    <div className="value">{item.yyqy}</div>
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className="empty"
            style={{ display: historyList.length === 0 ? 'block' : 'none' }}
          >
            暂无预约记录
          </div>
        </Tabs.Tab>
      </Tabs>
      <Footer />
    </div>
  );
}
