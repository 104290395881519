import React, { useEffect, useState } from 'react';
import Header from '../../common/Header';
import { Button, Form, Grid, Radio, Space } from 'antd-mobile';
import { request } from '../../util/request';
import { FormInstance } from 'antd-mobile/es/components/form';
import { Business } from './Xwbl';

interface Zsxx {
  zsbs: string;
  lzfs: string;
  ywlsh: string;
}

function Zsxx(props: any) {
  const { ywlsh } = props.match.params;
  const formRef = React.createRef<FormInstance>();
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(true);

  const getZsInfo = () => {
    request.get<Zsxx>(`/inquiry/getZsInfo/${ywlsh}`).then((data) => {
      // 设置表单值
      formRef.current?.setFieldsValue(data);
    });
  };
  const getBusiness = () => {
    request.get<Business>(`/inquiry/getBusiness/${ywlsh}`).then((data) => {
      // 设置表单值
      setEdit(data.submit === 'y');
    });
  };
  useEffect(() => {
    getZsInfo();
    getBusiness();
  }, []);
  const onFinish = (values: Zsxx) => {
    setLoading(true);
    values.ywlsh = ywlsh;
    request
      .post<any>('/inquiry/zsInfoSave', values)
      .then(() => {
        window.location.href = `#/xwbl/${ywlsh}`;
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      <Header name={'证书板式'} />
      <div>
        <Form
          name="form"
          disabled={edit}
          ref={formRef}
          onFinish={onFinish}
          footer={
            <Grid columns={2} gap={12}>
              <Grid.Item>
                <Button
                  block
                  color="primary"
                  size="large"
                  onClick={() => (window.location.href = `/#/sdq/${ywlsh}`)}
                >
                  上一步
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Button
                  block
                  type="submit"
                  color="primary"
                  size="large"
                  loading={loading}
                >
                  下一步
                </Button>
              </Grid.Item>
            </Grid>
          }
        >
          <Form.Header>申请信息</Form.Header>
          <Form.Item
            name="zsbs"
            label="申请证书板式"
            rules={[{ required: true }]}
          >
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="1">单一版</Radio>
                <Radio value="2">集成版</Radio>
                <Radio value="3">电子证明</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="lzfs" label="领证方式" rules={[{ required: true }]}>
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="1">窗口领证</Radio>
                <Radio value="2">自助领证</Radio>
                <Radio value="3">邮寄</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

export default Zsxx;
