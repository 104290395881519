import React, { useEffect, useRef, useState } from 'react';
import { Mask } from 'antd-mobile';
import { request } from '../../util/request';

export default function DzzzFace() {
  useEffect(() => {
    request.get<string>(`/faceAuth/createQuery/dzzzYn`).then((url) => {
      window.location.href = url;
    });
  }, []);

  return (
    <>
      <div>人脸识别跳转中...</div>
      <Mask color="white" visible={true} />
    </>
  );
}
