import Header from '../../common/Header';
import Footer from '../../common/Footer';
import { Popup, Card, List, Divider } from 'antd-mobile';
import { useEffect, useState } from 'react';
import { request } from '../../util/request';

interface Ywzn {
  name: string;
  list: YwznItem[];
}

interface YwznItem {
  djlx: string;
  syfw: string;
  sqzt: string;
  sqcl: string;
  fdgzsx: string;
  cngzsx: string;
  bz: string;
}

export default function ServiceGuide() {
  const [ywzn, setYwzn] = useState<Ywzn[]>([]);
  const [itemList, setItemList] = useState<YwznItem[]>([]);
  const [itemCur, setItemCur] = useState<YwznItem>();
  const [visibleCloseRight, setVisibleCloseRight] = useState(false);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    request.get<Ywzn[]>('/addressInfo/ywzn').then((data) => {
      setYwzn(data);
    });
  }, []);

  function handleClick(itemList: YwznItem[]) {
    setItemList(itemList);
    setVisibleCloseRight(true);
  }

  function handleClickChild(itemList: YwznItem) {
    setVisible(true);
    setItemCur(itemList);
  }

  return (
    <>
      <Header name={'业务指南'} />
      <List>
        {ywzn.map((item, index) => {
          return (
            <List.Item key={index} onClick={() => handleClick(item.list)}>
              {item.name}
            </List.Item>
          );
        })}
      </List>
      <Popup
        position="right"
        bodyStyle={{ width: '100%' }}
        visible={visibleCloseRight}
        showCloseButton
        onClose={() => {
          setVisibleCloseRight(false);
        }}
      >
        <div style={{ paddingTop: 60 }}>
          <List>
            {itemList.map((itemChild, indexChild) => {
              return (
                <List.Item
                  key={indexChild}
                  onClick={() => handleClickChild(itemChild)}
                >
                  {itemChild.djlx}
                </List.Item>
              );
            })}
          </List>
        </div>
      </Popup>
      <Popup
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        position="right"
        showCloseButton
        bodyStyle={{
          width: '100%',
          overflowY: 'scroll',
          paddingTop: 40,
          paddingBottom: 20,
        }}
      >
        <Card title={'适用范围'}>{itemCur?.syfw}</Card>
        <Card title={'申请主体'}>{itemCur?.sqzt}</Card>
        <Card title={'申请材料'}>
          {itemCur?.sqcl.split('<br>').map((item, index) => {
            return <div key={index}>{item}</div>;
          })}
        </Card>
        <Card title={'法定工作时限'}>{itemCur?.fdgzsx}</Card>
        <Card title={'承诺工作时限'}>{itemCur?.cngzsx}</Card>
        <Card title={'备注'}>{itemCur?.bz}</Card>
        <Divider></Divider>
      </Popup>
      <Footer />
    </>
  );
}
