import Header from '../../common/Header';
import React, {useEffect, useState} from 'react';
import {requestEstate} from '../../util/requestEstate';
import {Button, Tabs, Cascader, Dialog, Modal, PullToRefresh, Toast, Popup} from "antd-mobile";
import './AppointmentPanel.css';
import './AppointmentRules.css';

interface Appointment {
    ddid: string; // 办理地点ID
    ddmc: string; // 办理地点名称
    dh: string; // 电话
    hdJssj: string; // 预约开始时间（结束时间的小时部分）
    hdKssj: string; // 预约开始时间（开始时间的小时部分）
    hdid: string; // 办理时段ID
    jlid: string; // 可能是业务流水号或相关ID
    qhdm: string; // 可能是区域或行政区划代码
    sqsj: string; // 申请时间
    userid: string; // 用户ID
    xm: string; // 姓名
    ywid: string; // 业务ID
    ywmc: string; // 业务名称
    yyh: string; // 预约号
    yyrq: string; // 预约日期（格式为YYYYMMDD）
    yyrq_full: string; // 预约日期（完整格式，包括时间和时间段）
    yyzt: number; // 预约状态（可能是枚举值，-1代表已取消）
    yyzt_mc: string; // 预约状态名称（已取消的文字描述）
}

interface User {
    name: string;
    xm: string;
    dh: string;
    zjh: string;
}

interface Options {
    label: string;
    value: string;
    disabled?: boolean;
    children: Options[];
}

interface Submit {
    ddid: string;
    hdid: string;
    ywlxid: string;
    yyrq: string;
}

export default function FhtSn() {
    const [user, setUser] = useState<User>({name: '', xm: '', dh: '', zjh: ''});
    const [submit, setSubmit] = useState<Submit>({ddid: '', hdid: '', ywlxid: '', yyrq: ''});
    const [visible1, setVisible1] = useState(false)
    const [visible2, setVisible2] = useState(false)
    const [value1, setValue1] = useState<string[]>([])
    const [value2, setValue2] = useState<string[]>([])
    const [ywmc, setYwmc] = useState<string>('')
    const [bdsd, setBdsd] = useState<string>('')
    const [options1, setOptions1] = useState<Options[]>([])
    const [options2, setOptions2] = useState<Options[]>([])
    const [loading, setLoading] = useState(false)
    const [pageSize, setPageSize] = useState(5)
    const [appointments, setAppointments] = useState<Appointment[]>([])
    const [visible3, setVisible3] = useState(true)
    useEffect(() => {
        requestEstate.post<any>('/sys/wxLogin').then((data) => {
            if (data?.success && data?.result.token) {
                localStorage.setItem('token', data.result.token);
                // 同步执行getRegionBusinessList
                getRegionBusinessList();
            }
        });
    }, []);


    async function dateFetch(value: string): Promise<Options[]> {
        return requestEstate.get<any>(`/appointment/numbers/${submit.ddid}/${value}`).then((res) => {
            let options: Options[] = [];
            if (res.success && res?.result) {
                res?.result.forEach((item: any) => {
                    let child: {} = {
                        label: item.hd.hdmc + '（剩余' + item.hd.hs + '）',
                        value: item.hd.hdid,
                        disabled: !item.enable,
                    };
                    options.push(child as Options);
                });
            }
            return options;
        });
    }

    async function fetchOptionsForValue(rq: string) {
        if (!submit.ddid) {
            // 请先选择办理地点
            await Dialog.alert({
                content: '请选择办理地点',
            })
            return;
        }
        const data = await dateFetch(rq);
        // options2的找到value与rq对应的那个节点，并把那个节点的children设置为data
        let options: Options[] = [];
        options2.forEach((item) => {
            if (item.value === rq) {
                item.children = data;
            }
            options.push(item);
        });
        setOptions2(options);
    }

    const getRegionBusinessList = () => {
        requestEstate.get<any>('/appointment/regionbusiness').then((data) => {
            if (data?.success && data?.result.regions) {
                let options: Options[] = [];
                data?.result.regions.forEach((region: any) => {
                    let parent: Options = {
                        label: region.qhmc,
                        value: region.qhdm,
                        children: []
                    }
                    let children1: Options[] = [];
                    region?.adresses.forEach((adress: any) => {
                        let children: Options = {
                            label: adress.bldd.xxdz,
                            value: adress.bldd.ddid,
                            children: []
                        }
                        let children2: Options[] = [];
                        adress?.ywlxs.forEach((ywlx: any) => {
                            let child: { label: any; value: any } = {
                                label: ywlx.ywmc,
                                value: ywlx.ywid,
                            }
                            children2.push(child as Options);
                        })
                        children.children = children2;
                        children1.push(children);
                    });
                    parent.children = children1;
                    options.push(parent);
                });
                setOptions1(options);
            }
            if (data?.success && data?.result.dates) {
                let options: Options[] = [];
                data?.result.dates.forEach((date: any) => {
                    let parent: Options = {
                        label: date.label,
                        value: date.value,
                        children: []
                    }
                    options.push(parent);
                });
                setOptions2(options);
            }
            if (data?.success && data?.result.xm && data?.result.dh && data?.result.zjh) {
                setUser({name: data.result.xm, xm: data.result.xm, dh: data.result.dh, zjh: data.result.zjh});
            }
        });
    }

    const submitOrder = () => {
        if (submit.hdid === '' || submit.ywlxid === '' || submit.ddid === '' || submit.yyrq === '') {
            Dialog.alert({
                content: '请填写完整信息',
            })
            return;
        }
        setLoading(true);
        requestEstate.post<any>('/appointment/submit', submit).then((data) => {
            if (data?.success) {
                Dialog.alert({
                    content: `${data?.result.xm} 用户，您已成功预约, ${data?.result.yyrq_full} 在 ${data?.result.ddmc} 申请办理  ${data?.result.ywmc}  业务，预约号: ${data?.result.yyh} ，请携带办理资料按时到约定地点取号办理业务，如要取消预约请到我的预约里面进行取消。`,
                })
            } else {
                Dialog.alert({
                    content: data?.message,
                })
            }
        }).finally(() => {
            setLoading(false);
        });
    }
    const tabChange = (key: string) => {
        if (key === 'wdyy') {
            yyList();
        }
    }
    // 取消预约
    const qxyy = (id: string) => {
        requestEstate.get<any>(`/appointment/cancel/${id}`).then((data) => {
            Toast.show({content: '取消成功', position: 'bottom'});
            setPageSize(5);
            yyList();
        })
    }
    const yyList = () => {
        let params = {
            pageNo: 1,
            pageSize: pageSize,
            yyzt: 1,
        }
        requestEstate.post<any>('/appointment/my', params).then((data) => {
            if (data?.success && data?.result) {
                let list = data?.result.records
                // 把list转成Appointment数组
                let appointments: Appointment[] = [];
                list.forEach((item: any) => {
                    let appointment: Appointment = {
                        ddid: item?.ddid,
                        ddmc: item?.ddmc,
                        dh: item?.dh,
                        hdJssj: item?.hdJssj,
                        hdKssj: item?.hdKssj,
                        hdid: item?.hdid,
                        jlid: item.jlid,
                        qhdm: item?.qhdm,
                        sqsj: item?.sqsj,
                        userid: item?.userid,
                        xm: item?.xm,
                        ywid: item?.ywid,
                        ywmc: item?.ywmc,
                        yyh: item?.yyh,
                        yyrq: item?.yyrq,
                        yyrq_full: item?.yyrq_full,
                        yyzt: item?.yyzt,
                        yyzt_mc: item?.yyzt_mc,
                    }
                    appointments.push(appointment);
                });
                setAppointments(appointments);
            }
        });
    }
    const styles = {
        tabContent: {
            padding: '20px', // 根据需要调整内边距
            // 其他全局样式...
        },
        title: {
            fontSize: '18px',
            fontWeight: 'bold',
            marginBottom: '10px', // 调整标题与内容之间的间距
        },
        infoItem: {
            marginBottom: '10px', // 调整信息项之间的间距
        },
        label: {
            display: 'inline-block',
            width: '80px', // 设置标签宽度，使显示更整齐
            marginRight: '10px', // 调整标签与值之间的间距
            fontWeight: 'bold',
        },
        buttonGroup: {
            marginTop: '20px', // 调整按钮组与上方内容的间距
            // 其他按钮组样式...
        },
        submitButton: {
            marginTop: '20px', // 调整提交按钮与上方内容的间距
            // 其他提交按钮样式...
        },
    };
    return (
        <>
            <Header name={'在线预约'}/>
            <Tabs onChange={(key) => tabChange(key)} defaultActiveKey={'wsyy'}>
                <Tabs.Tab title='网上预约' key='wsyy'>
                    <Cascader
                        options={options1}
                        visible={visible1}
                        onClose={() => {
                            setVisible1(false)
                        }}
                        value={value1}
                        onSelect={(val, extend) => {
                            console.log('onSelect', val, extend.items)
                            let mc: string = '';
                            extend.items.forEach((item: any, index) => {
                                if (item.label) {
                                    mc += item.label + '-';
                                }
                                if (index === 1) {
                                    setSubmit((prev) => {
                                        return {
                                            ...prev,
                                            ddid: item.value,
                                        }
                                    });
                                }
                                if (index === 2) {
                                    setSubmit((prev) => {
                                        return {
                                            ...prev,
                                            ywlxid: item.value,
                                        }
                                    });
                                }
                            });
                            mc = mc.substring(0, mc.length - 1);
                            setYwmc(mc);
                            console.log(submit);
                        }}

                    />
                    <Cascader
                        options={options2}
                        visible={visible2}
                        onClose={() => {
                            setVisible2(false)
                        }}
                        value={value2}
                        onSelect={(val, extend) => {
                            console.log('onSelect', val, extend.items)
                            let mc: string = '';
                            extend.items.forEach((item: any, index) => {
                                if (item.label) {
                                    mc += item.label + '-';
                                }
                                if (index === 0) {
                                    setSubmit((prev) => {
                                        return {
                                            ...prev,
                                            yyrq: item.value,
                                        }
                                    });
                                }
                                if (index === 1) {
                                    setSubmit((prev) => {
                                        return {
                                            ...prev,
                                            hdid: item.value,
                                        }
                                    });
                                }
                                if (extend.items.length <= 1) {
                                    fetchOptionsForValue(item.value);
                                }
                            });
                            mc = mc.substring(0, mc.length - 1);
                            // 去掉mc中括号里面的内容
                            let index = mc.indexOf('（');
                            if (index !== -1) {
                                mc = mc.substring(0, index);
                            }
                            setBdsd(mc);
                        }}

                    />
                    <div style={styles.tabContent}>
                        <h3 style={styles.title}>申请人信息</h3>
                        <div style={styles.infoItem}>
                            <span style={styles.label}>姓名：</span>
                            {user.xm}
                        </div>
                        <div style={styles.infoItem}>
                            <span style={styles.label}>证件号：</span>
                            {user.zjh}
                        </div>
                        <div style={styles.infoItem}>
                            <span style={styles.label}>电话：</span>
                            {user.dh}
                        </div>
                        <h3 style={styles.title}>选择办理业务</h3>

                        <div style={styles.buttonGroup}>
                            <Button
                                color='primary'
                                fill='outline'
                                onClick={() => {
                                    setVisible1(true)
                                }}
                            >
                                选择办理地点
                            </Button>
                            <h3>{ywmc}</h3>
                            {/* ... 其他按钮和级联选择器 ... */}
                        </div>
                        <div style={styles.buttonGroup}>
                            <Button
                                color='primary'
                                fill='outline'
                                disabled={submit.ddid === '' || submit.ywlxid === ''}
                                onClick={() => {
                                    setVisible2(true)
                                }}
                            >
                                选择办理时段
                            </Button>
                            <h3>{bdsd}</h3>
                        </div>

                        <Button
                            onClick={submitOrder}
                            loading={loading}
                            loadingText='正在提交'
                            style={styles.submitButton}
                            block
                            color='primary'
                            size='large'
                        >
                            提交预约申请
                        </Button>
                    </div>
                </Tabs.Tab>
                <Tabs.Tab title='我的预约' key='wdyy'>
                    <PullToRefresh
                        onRefresh={async () => {
                            setPageSize(pageSize + 5);
                            await yyList();
                        }}
                    >
                        <div style={{width: '100%', textAlign: 'center'}}>下拉加载更多</div>
                        {
                            appointments.map((item, index) => {
                                return (
                                    <div className="panel" key={index}>
                                        <div className="panel-title">预约信息</div>
                                        <div className="panel-item">
                                            <label>预约时间：</label>
                                            <span>{item.yyrq_full}</span>
                                        </div>
                                        <div className="panel-item">
                                            <label>预约号：</label>
                                            <span>{item.yyh}</span>
                                        </div>
                                        <div className="panel-item">
                                            <label>办理地点：</label>
                                            <span>{item.ddmc}</span>
                                        </div>
                                        <div className="panel-item">
                                            <label>业务类型：</label>
                                            <span>{item.ywmc}</span>
                                        </div>
                                        <div className="panel-item">
                                            <label>预约状态：</label>
                                            <span>{item.yyzt_mc}</span>
                                        </div>
                                        <div
                                            className="panel-item panel-item--action"> {/* 添加一个用于操作项的额外类名 */}
                                            <Button
                                                disabled={item.yyzt !== 1}
                                                onClick={async () => {
                                                    const result = await Modal.confirm({
                                                        content: '确认取消预约吗？',
                                                    })
                                                    if (result) {
                                                        qxyy(item.jlid)
                                                    }
                                                }}
                                                size='mini' color='primary' fill='outline'>
                                                取消预约
                                            </Button>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </PullToRefresh>
                </Tabs.Tab>
            </Tabs>
            <Popup
                visible={visible3}
                onMaskClick={() => {
                    setVisible3(false)
                }}
                onClose={() => {
                    setVisible3(false)
                }}
                position='top'
                bodyStyle={{height: '100vh'}}
            >
                <div className="appointment-rules-container">
                    <h2>遂宁市不动产登记网上预约须知</h2>
                    <div className="rule-section">
                        <h3>温馨提示：</h3>
                        <p>企业办理存量房交易业务，可直接前往各登记中心企业服务专窗办理，无需任何预约取号。</p>
                    </div>
                    <div className="rule-section">
                        <h3>预约规则：</h3>
                        <ol>
                            <li>
                                预约采用实名制，预约人须为不动产登记当事人（包括交易双方、所有权人、抵押人、抵押权人及其代理人等）。预约人须如实填写预约信息，如现场资料与预约信息不符，不动产登记机构将不予办理。
                            </li>
                            <li>
                                无法如期前来办理的，预约人应在预约时间段结束前2个小时通过预约平台自行取消预约；无法网上自行取消的，可向提供预约服务的任一登记机构申请取消。
                            </li>
                            <li>
                                同一手机号一天内取消预约两次及以上当天不能预约，同一个月内取消预约三次及以上当月不能预约；同一房屋同一业务类型一个月连续3次预约成功后取消办理的，当月不能再次预约。
                            </li>
                            <li>
                                同一手机号一天内取消预约两次及以上当天不能预约，同一个月内取消预约三次及以上当月不能预约；同一房屋同一业务类型一个月连续3次预约成功后取消办理的，当月不能再次预约。
                            </li>
                            <li>
                                同一房屋如有未办结的预约，须待现有预约办结或取消后方可再次预约。
                            </li>
                            <li>
                                同一手机号同一时段只能预约一个登记机构。
                            </li>
                            <li>
                                全部申请人须在约定日期预约时段的最后10分钟前（如：预约10：00-11：00的须于当天10：50前报到）带齐全部登记资料前往约定登记机构报到，预约下午时段的只能下午报到，逾期报到或资料不齐的预约失效。
                            </li>
                            <li>
                                约定办理时间未按时报到的视作失约，三次失约将被纳入“失信名单”，三个月内无法进行网上预约。
                            </li>
                        </ol>
                    </div>
                    <Button onClick={() => setVisible3(false)} style={styles.submitButton} block color='primary'
                            size='large'>
                        我同意
                    </Button>
                </div>
            </Popup>
        </>
    );
}
