import Header from '../../common/Header';
import City from '../../common/City';
import PdfViewYn from '../../common/PdfViewYn';
import React, { useRef, useState } from 'react';
import {
  Button,
  Footer as AntFooter,
  Grid,
  List,
  Popup,
  Space,
} from 'antd-mobile';
import { SearchOutline } from 'antd-mobile-icons';
import { request } from '../../util/request';
import cqzs from '../../img/cqzs.png';

interface DzzzHouseVo {
  zl: string;
  bdcqzh: string;
  bdcdyh: string;
  cxrxm: string;
  cxrzjh: string;
  zsid: string;
  qhdm: string;
}

interface ZsInfoVo {
  qllx: string;
  bdcdyh: string;
  qhmc: string;
  yt: string;
  mj: string;
  qhjc: string;
  qlxz: string;
  zl: string;
  bdcqzh: string;
  qlr: string;
  syqx: string;
  gyqk: string;
  fj: string;
  year: string;
  month: string;
  day: string;
  nd: string;
  qlqtzk: string;
  zsid: string;
  qrcode: string;
  qrcontent: string;
  sxh: string;
  zsbh: string;
  sfzt: string;
  accessToken: string;
}

export default function DzzzYn() {
  const cityRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [zsinfoLoading, setZsinfoLoading] = useState(false);
  const [houseList, setHouseList] = useState<DzzzHouseVo[]>([]);
  const [zsInfo, setZsInfo] = useState<ZsInfoVo>();
  const [zsInfoVisible, setZsInfoVisible] = useState(false);
  const [zsPdfVisible, setZsPdfVisible] = useState(false);
  const [filePath, setFilePath] = useState('');
  const queryHouse = () => {
    setLoading(true);
    // @ts-ignore
    const qhdm = cityRef.current && cityRef.current.getData();
    request
      .get<DzzzHouseVo[]>(`/sinfCloud/queryHouseByQlr/${qhdm}`)
      .then((data) => {
        setHouseList(data);
      })
      .finally(() => setLoading(false));
  };
  const queryZs = (zsid: string, qhdm: string) => {
    setZsinfoLoading(true);
    request
      .get<ZsInfoVo>(`/sinfCloud/queryHouseByZsid/${qhdm}/${zsid}`)
      .then((data) => {
        setZsInfoVisible(true);
        setZsInfo(data);
      })
      .finally(() => setZsinfoLoading(false));
  };
  return (
    <>
      <Header name={'电子证照'} />
      <div style={{ padding: 10 }}>
        <City ref={cityRef} />
        <Button
          onClick={queryHouse}
          style={{ marginTop: 10 }}
          block
          color="primary"
          size="large"
          loading={loading}
        >
          <Space>
            <SearchOutline />
            <span>查询</span>
          </Space>
        </Button>
      </div>
      <AntFooter
        label={
          <div>
            <p>
              {houseList.length === 0
                ? '暂无数据'
                : `共${houseList.length}条记录`}
            </p>
          </div>
        }
      />
      <div style={{ padding: '0px 6px' }}>
        {houseList.map((item, index) => {
          return (
            <Grid columns={3} gap={8} key={index} style={{ marginTop: 6 }}>
              <Grid.Item>
                <div>
                  <img width={'96%'} src={cqzs} alt={'没有数据'} />
                </div>
              </Grid.Item>
              <Grid.Item span={2} style={{ fontSize: 12 }}>
                <div>权证号：{item.bdcqzh}</div>
                <div>单元号：{item.bdcdyh}</div>
                <div>权利人：{item.cxrxm}</div>
                <div>坐落：{item.zl}</div>
                <div style={{ paddingTop: 20 }}>
                  <Button
                    color="primary"
                    fill="outline"
                    loading={zsinfoLoading}
                    onClick={() => {
                      queryZs(item.zsid, item.qhdm);
                    }}
                    size={'small'}
                  >
                    证照出示
                  </Button>
                  <Button
                    color="primary"
                    fill="outline"
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      setZsPdfVisible(true);
                      setFilePath(
                        `/file/yn_dzzz_file/${item.qhdm}/${item.zsid}`
                      );
                    }}
                    size={'small'}
                  >
                    证照预览
                  </Button>
                </div>
              </Grid.Item>
            </Grid>
          );
        })}
      </div>
      <Popup
        visible={zsInfoVisible}
        onMaskClick={() => {
          setZsInfoVisible(false);
        }}
        bodyStyle={{ height: '90vh' }}
      >
        <List header="证书信息">
          <List.Item>不动产权证号:{zsInfo?.bdcqzh}</List.Item>
          <List.Item>权利人:{zsInfo?.qlr}</List.Item>
          <List.Item>共有情况:{zsInfo?.gyqk}</List.Item>
          <List.Item>坐落:{zsInfo?.zl}</List.Item>
          <List.Item>权利类型:{zsInfo?.qllx}</List.Item>
          <List.Item>权利性质:{zsInfo?.qlxz}</List.Item>
          <List.Item>用途:{zsInfo?.yt}</List.Item>
          <List.Item>使用期限:{zsInfo?.syqx}</List.Item>
        </List>
        <div style={{ padding: 10 }}>
          <Button
            block
            color="primary"
            fill="solid"
            onClick={() => setZsInfoVisible(false)}
          >
            返回
          </Button>
        </div>
      </Popup>
      <Popup
        visible={zsPdfVisible}
        onMaskClick={() => {
          setZsPdfVisible(false);
        }}
        bodyStyle={{ height: '90vh' }}
      >
        <PdfViewYn filePath={filePath} />
      </Popup>
    </>
  );
}
