import Header from '../../common/Header';
import React, { useEffect, useRef, useState } from 'react';
import { wx, init } from '../../util/WxUtil';
import sm from '../../icon/sm.svg';
import { Mask } from 'antd-mobile';
import City from '../../common/City';
import { request } from '../../util/request';
import { message } from 'antd';

interface FhtInfo {
  bdcqzh: string;
  zl: string;
  bdcdyh: string;
  dylxmc: string;
  ftmc: string;
  base64: string;
  zrzzdtmc: string;
  zrzzdtBase64: string;
}

export default function FhtSn() {
  const cityRef = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = React.useState(false);
  const [url, setUrl] = React.useState('');
  const [data, setData] = React.useState<FhtInfo[]>([]);
  useEffect(() => {
    init();
  }, []);
  return (
    <>
      <Header name={'分户图扫一扫'} />
      <div style={{ padding: 10 }}>
        <City ref={cityRef} />
      </div>
      <div
        onClick={() => {
          setVisible(true);
          wx.ready(() => {
            wx.scanQRCode({
              needResult: 1,
              scanType: ['qrCode', 'barCode'],
              success: function (res: { resultStr: any }) {
                // const result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                // const url = res.resultStr.split('&')[0] || '';
                // setUrl(res.resultStr);
                // @ts-ignore
                const xzqdm = cityRef.current && cityRef.current.getData();
                // 判断xzqdm是否为空
                if (xzqdm === undefined || xzqdm === null || xzqdm === '') {
                  message.error('请选择行政区');
                } else {
                  request
                    .post<FhtInfo[]>(`/sinfCloud/fht/${xzqdm}`, {
                      bdcqzh: res.resultStr,
                    })
                    .then((res) => {
                      setData(res);
                    })
                    .finally(() => setVisible(false));
                }
              },
              fail: function (err: any) {
                setVisible(false);
                alert(JSON.stringify(err));
              },
              cancel: function () {
                setVisible(false);
              },
            });
          });
        }}
        style={{ width: '100%', textAlign: 'center' }}
      >
        <div>
          <img src={sm} alt="" width={100} style={{ marginTop: '10vh' }} />
        </div>
        <div style={{ color: '#009FE8', fontSize: 16 }}>扫一扫</div>
      </div>

      <div>
        {data.map((item, index) => (
          <div key={index}>
            <table>
              <tbody>
                <tr>
                  <td>权证号</td>
                  <td>{item.bdcqzh}</td>
                </tr>
                <tr>
                  <td>坐落</td>
                  <td>{item.zl}</td>
                </tr>
                <tr>
                  <td>不动产单元号</td>
                  <td>{item.bdcdyh}</td>
                </tr>
                <tr>
                  <td>单元类型</td>
                  <td>{item.dylxmc}</td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <h4>{item.ftmc}</h4>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <img width="100%" src={item.base64} alt={'暂无图片'} />
            </div>
            <table>
              <tbody>
                <tr>
                  <td>
                    <h4>{item.zrzzdtmc}</h4>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <img width="100%" src={item.zrzzdtBase64} alt={'暂无图片'} />
            </div>
          </div>
        ))}
        <Mask color="white" visible={visible} />
      </div>
    </>
  );
}
