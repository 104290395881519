import Header from '../common/Header';
import React, { useEffect, useState } from 'react';
import ZcfgPdfView from '../common/ZcfgPdfView';
import {
  Button,
  Footer,
  Form,
  Input,
  List,
  Popup,
  PullToRefresh,
  Toast,
} from 'antd-mobile';
import { sleep } from 'antd-mobile/es/utils/sleep';
import { SearchOutline } from 'antd-mobile-icons';
import { request } from '../util/request';

interface file {
  title: string;
  url: string;
  id: string;
}

export default function Zcfg() {
  const [loading, setLoading] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(5);
  const [files, setFiles] = React.useState<file[]>([]);
  const [title, setTitle] = React.useState('');
  const [visibleCloseRight, setVisibleCloseRight] = useState(false);
  const [fileUrl, setFileUrl] = useState<string>('');

  const getFileList = (fileName: string, type: boolean) => {
    request
      .get<file[]>(`/zcfg/list?pageSize=${pageSize}&fileName=${fileName}`)
      .then((data) => {
        // Toast.show({
        //   icon: 'success',
        //   content: '加载成功！',
        // });
        setFiles(data);
        if (type) {
          const page = pageSize + 5;
          setPageSize(page);
        }
        setLoading(false);
      })
      .catch(() => {
        throw new Error('刷新失败');
      })
      .finally(() => setLoading(false));
  };

  async function doRefresh() {
    await sleep(1000);
    getFileList(encodeURI(title), true);
  }

  useEffect(() => {
    getFileList(encodeURI(title), false);
  }, []);

  return (
    <>
      <Header name={'政策法规'} />
      <Form layout="horizontal">
        <Form.Item
          extra={
            <div
              style={{
                borderLeft: 'solid 1px #eeeeee',
                paddingLeft: 12,
                fontSize: 17,
              }}
              onClick={() => {
                // title含有中文时，需要使用encodeURI进行编码
                setPageSize(5);
                getFileList(encodeURI(title), false);
              }}
            >
              <a>查询</a>
              <SearchOutline style={{ marginLeft: 8 }} color={'#1677ff'} />
            </div>
          }
        >
          <Input
            placeholder="请输入搜索关键字"
            clearable
            onChange={(e) => setTitle(e)}
          />
        </Form.Item>
      </Form>
      <PullToRefresh onRefresh={doRefresh}>
        <List>
          {files.map((file) => {
            return (
              <List.Item
                key={file.id}
                clickable
                onClick={() => {
                  setVisibleCloseRight(true);
                  setFileUrl(file.url);
                }}
              >
                {file.title}
              </List.Item>
            );
          })}
        </List>
        <Footer content="下拉加在更多"></Footer>
      </PullToRefresh>
      <Popup
        position="right"
        showCloseButton
        visible={visibleCloseRight}
        bodyStyle={{
          width: '100%',
          height: '100vh',
          overflow: 'auto',
          paddingTop: 20,
        }}
        onClose={() => {
          setVisibleCloseRight(false);
        }}
      >
        <ZcfgPdfView url={fileUrl} />
      </Popup>
    </>
  );
}
