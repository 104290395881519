import Header from '../../common/Header';
import React, { useRef, useState } from 'react';
import { Button, Popup, Toast } from 'antd-mobile';
import City from '../../common/City';
import { request } from '../../util/request';
import PdfViewSinf from '../../common/PdfViewSinf';
import { message } from 'antd';

export default function Fwtc() {
  const cityRef = useRef<HTMLDivElement>(null);
  const [fileName, setFileName] = useState<string>();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const queryFwtc = () => {
    // @ts-ignore
    const qhdm = cityRef.current && cityRef.current.getData();
    if (qhdm === undefined || qhdm === null || qhdm === '') {
      Toast.show({
        content: '请选择行政区',
        position: 'top',
      });
      return;
    }
    setLoading(true);
    request
      .get<string>(`/houseQuery/amount/${qhdm}`)
      .then((path) => {
        setFileName(path);
        setVisible(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Header name={'房屋套次'} />
      <div style={{ padding: 10 }}>
        <City ref={cityRef} />
        <Button
          style={{ marginTop: 20 }}
          block
          color="primary"
          onClick={queryFwtc}
          size={'large'}
          loading={loading}
        >
          查询
        </Button>
        <Popup
          visible={visible}
          showCloseButton
          onClose={() => {
            setVisible(false);
          }}
        >
          <div style={{ height: '96vh' }}>
            <PdfViewSinf fileName={fileName} />
          </div>
        </Popup>
      </div>
    </>
  );
}
