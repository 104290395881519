import { Button, Form, Input, List } from 'antd-mobile';
import { request } from '../util/request';
import React, { useEffect, useState } from 'react';
import '../css/PersonalCenter.css';
import Header from './Header';
import { FormInstance } from 'antd-mobile/es/components/form';

export interface UserResponse {
  name: string;
  phone: string;
  idCard: string;
  faceAuth: string;
}

export default function PersonalCenter() {
  const formRef = React.createRef<FormInstance>();
  const [user, setUser] = useState<UserResponse>();
  const [loading, setLoading] = useState(false);
  const [authLoading, setAuthLoading] = useState(false);
  const loadUser = () => {
    request.get<UserResponse>('/auth/user_info').then((data) => {
      setUser(data);
      formRef.current?.setFieldsValue(data);
    });
  };
  const faceAuth = () => {
    setAuthLoading(true);
    request
      .post<string>('/faceAuth/createUrl')
      .then((url) => {
        window.location.href = url;
      })
      .finally(() => setAuthLoading(false));
  };
  useEffect(() => {
    loadUser();
  }, []);

  const onFinish = (values: { name: string }) => {
    setLoading(true);
    request
      .post('/auth/add_user', values)
      .then(() => {
        loadUser();
      })
      .finally(() => setLoading(false));
  };
  return (
    <div style={{ width: '100%' }}>
      <Header name={'用户中心'} />
      {/*{user == null || user.faceAuth !== 'no' ? (*/}
      <Form
        onFinish={onFinish}
        ref={formRef}
        layout="horizontal"
        footer={
          <>
            <Button
              block
              type="submit"
              color="primary"
              size={'large'}
              loading={loading}
            >
              {user == null ? '注册' : '修改'}
            </Button>
            {user && user.faceAuth === 'no' ? (
              <Button
                style={{ marginTop: 10 }}
                size={'large'}
                block
                color="primary"
                onClick={faceAuth}
                loading={authLoading}
                loadingText="正在加载"
              >
                去实名
              </Button>
            ) : null}
          </>
        }
      >
        <Form.Item style={{ display: 'none' }} name="id">
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          label="姓名"
          rules={[{ required: true, message: '姓名不能为空' }]}
        >
          <Input onChange={console.log} placeholder="请输入姓名" />
        </Form.Item>
        <Form.Item
          name="idCard"
          label="证件号"
          rules={[
            { required: true, message: '证件号为15到18位', min: 15, max: 18 },
          ]}
        >
          <Input onChange={console.log} placeholder="请输入证件号" />
        </Form.Item>
        <Form.Item
          name="phone"
          label="手机号"
          rules={[
            { required: true, message: '手机号不能为空', min: 11, max: 11 },
          ]}
        >
          <Input onChange={console.log} placeholder="请输入手机号" />
        </Form.Item>
      </Form>
      {/*) : (*/}
      {/*  <List header="用户信息">*/}
      {/*    <List.Item>用户名：{user.name}</List.Item>*/}
      {/*    <List.Item>证件号：{user.idCard}</List.Item>*/}
      {/*    <List.Item>手机号：{user.phone}</List.Item>*/}
      {/*  </List>*/}
      {/*)}*/}
      {/*<Footer />*/}
    </div>
  );
}
