import Header from '../common/Header';
import { List } from 'antd-mobile';
import Footer from '../common/Footer';

export default function Bszn() {
  const data = [
    {
      name: '变更登记（抵押权登记）个人 法人',
      url: 'http://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510400000000&itemCode=511F0093300087-510400000000-000-11510300MB19382606-1-00&taskType=7&deptCode=115103007847000716',
    },
    {
      name: '转移登记（抵押权登记）个人 法人',
      url: 'http://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510400000000&itemCode=511F0093300091-510400000000-000-11510300MB19382606-1-00&taskType=7&deptCode=115103007847000716',
    },
    {
      name: '首次登记（抵押权登记）个人 法人',
      url: 'http://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510400000000&itemCode=511F0093300101-510400000000-000-11510300MB19382606-1-00&taskType=7&deptCode=115103007847000716',
    },
    {
      name: '注销登记（抵押权登记）个人 法人',
      url: 'http://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510400000000&itemCode=511F0093300092-510400000000-000-11510300MB19382606-1-00&taskType=7&deptCode=115103007847000716',
    },
    {
      name: '首次登记（国有建设用地使用权及房屋所有权登记）个人 法人',
      url: 'http://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510400000000&itemCode=511F0093300062-510400000000-000-11510300MB19382606-1-00&taskType=7&deptCode=115103007847000716',
    },
    {
      name: '转移登记（国有建设用地使用权及房屋所有权登记）个人 法人',
      url: 'http://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510400000000&itemCode=511F0093300064-510400000000-000-11510300MB19382606-1-00&taskType=7&deptCode=115103007847000716',
    },
    {
      name: '预告登记注销（预告登记）个人 法人',
      url: 'http://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510400000000&itemCode=511F0093300093-510400000000-000-11510300MB19382606-1-00&taskType=7&deptCode=115103007847000716',
    },
    {
      name: '注销登记（国有建设用地使用权及房屋所有权登记）个人 法人',
      url: 'http://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510400000000&itemCode=511F0093300065-510400000000-000-11510300MB19382606-1-00&taskType=7&deptCode=115103007847000716',
    },
    {
      name: '预告登记转移（预告登记）个人 法人',
      url: 'http://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510400000000&itemCode=511F0093300096-510400000000-000-11510300MB19382606-1-00&taskType=7&deptCode=115103007847000716',
    },
    {
      name: '预告登记设立（预告登记）个人 法人',
      url: 'http://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510400000000&itemCode=511F0093300100-510400000000-000-11510300MB19382606-1-00&taskType=7&deptCode=115103007847000716',
    },
    {
      name: '预告登记变更（预告登记）个人 法人',
      url: 'http://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510400000000&itemCode=511F0093300099-510400000000-000-11510300MB19382606-1-00&taskType=7&deptCode=115103007847000716',
    },
    {
      name: '不动产登记资料查询个人 法人',
      url: 'http://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510400000000&itemCode=512B0029400001-510400000000-000-12510300662766638L-1-00&taskType=20&deptCode=115103007847000716',
    },
    {
      name: '商品房分户转移登记、房产交易申报（不动产登记一件事专办事项）个人 法人',
      url: 'http://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510400000000&itemCode=511F1500100021-510400000000-000-12510300662766638L-1-00&taskType=7&deptCode=115103007847000716',
    },
    {
      name: '二手房转移登记、房产交易申报（不动产登记一件事专办事项）个人 法人',
      url: 'http://www.sczwfw.gov.cn/jiq/front/transition/ywTransToDetail?areaCode=510400000000&itemCode=511F1500100020-510400000000-000-12510300662766638L-1-00&taskType=7&deptCode=115103007847000716',
    },
  ];
  return (
    <>
      <Header name={'办事指南'} />
      <List header="办事指南事项">
        {data.map((item, index) => (
          <List.Item
            key={index}
            onClick={() => {
              window.location.href = item.url;
            }}
          >
            {item.name}
          </List.Item>
        ))}
      </List>
      <Footer />
    </>
  );
}
