import Header from '../../common/Header';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Popup } from 'antd-mobile';
import City from '../../common/City';
import { request } from '../../util/request';
import PdfViewSinf from '../../common/PdfViewSinf';

export default function Fwtc() {
  const cityRef = useRef<HTMLDivElement>(null);
  const [fileName, setFileName] = useState<string>();
  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const queryFwtc = () => {
    // @ts-ignore
    const qhdm = cityRef.current && cityRef.current.getData();
    request.get<string>(`/houseQuery/amount/${qhdm}`).then((path) => {
      setFileName(path);
      setVisible(true);
    });
  };

  useEffect(() => {
    request.get<string>(`/faceAuth/isAuthSuccess`).then((path) => {
      setDisabled(false);
    });
  }, []);
  return (
    <>
      <Header name={'房屋套次'} />
      <div style={{ padding: 10 }}>
        <City ref={cityRef} />
        <Button
          style={{ marginTop: 20 }}
          block
          color="primary"
          onClick={queryFwtc}
          size={'large'}
          disabled={disabled}
        >
          查询
        </Button>
        <Popup
          visible={visible}
          showCloseButton
          onClose={() => {
            setVisible(false);
          }}
        >
          <div style={{ height: '96vh' }}>
            <PdfViewSinf fileName={fileName} />
          </div>
        </Popup>
      </div>
    </>
  );
}
