import React, { useEffect } from 'react';
import { Dialog, Swiper, Toast } from 'antd-mobile';

import '../css/Home.css';
import Menu from './MenuItem';
import { request } from '../util/request';

interface City {
  cityName: string;
  cityCode: string;
  banner: Banner[];
}

interface Banner {
  id: string;
  imgUrl: string;
}

export default function Home() {
  // useEffect(() => {
  //   // 延时获取用户信息
  //   setTimeout(() => {
  //     request.get<UserResponse>('/auth/user_info').then(async (data) => {
  //       if (data == null) {
  //         const result = await Dialog.confirm({
  //           content: `未注册，是否前往注册？`,
  //         });
  //         if (result) {
  //           window.location.href = '/#/me';
  //         }
  //       } else if (data.faceAuth !== 'yes') {
  //         const result = await Dialog.confirm({
  //           content: `未进行实名认证，是否前往认证？`,
  //         });
  //         if (result) {
  //           window.location.href = '/#/me';
  //         }
  //       } else {
  //         localStorage.setItem('user', JSON.stringify(data));
  //       }
  //     });
  //   }, 1000);
  // }, []);
  const [city, setCity1] = React.useState<City>();
  const [banner, setBanner] = React.useState<Banner[]>([]);
  useEffect(() => {
    request.get<City>('/auth/getCity').then((data) => {
      if (data) {
        setCity1(data);
        setBanner(data?.banner);
        document.title = data.cityName;
        localStorage.setItem('cityName', data.cityName);
        localStorage.setItem('cityCode', data.cityCode);
      }
    });
  }, []);
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        backgroundColor: '#f0f0f0',
        overflowY: 'scroll',
      }}
    >
      <Swiper autoplay>
        {banner.map((item) => {
          return (
            <Swiper.Item key={item.id}>
              <div style={{ padding: 6 }}>
                <img
                  style={{ width: '100%' }}
                  src={item.imgUrl}
                  alt={'无图片'}
                />
              </div>
            </Swiper.Item>
          );
        })}
      </Swiper>
      <div style={{ padding: 6 }}>
        <Menu />
      </div>
    </div>
  );
}
