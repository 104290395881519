import Header from '../../common/Header';
import cqcx from '../../img/cqcx.png';
import { request } from '../../util/request';
import { Button, Mask, Popup } from 'antd-mobile';
import React, { useState } from 'react';
import Footer from '../../common/Footer';

const { useHistory } = require('react-router-dom');
export default function HouseQuery() {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [visibleCloseRight, setVisibleCloseRight] = useState(true);
  const getFaceUrl = (type: string) => {
    setVisible(true);
    request
      .get<string>(`/faceAuth/createQuery/${type}`)
      .then((url) => {
        window.location.href = url;
      })
      .finally(() => setVisible(false));
  };
  const goCqcx = () => {
    setVisible(true);
    request
      .get<string>(`/faceAuth/isAuthOpenid`)
      .then((status) => {
        if (status === 'yes') {
          history.push({ pathname: '/face_fwzt' });
        } else {
          getFaceUrl('face_fwzt');
        }
      })
      .finally(() => setVisible(false));
  };
  return (
    <>
      <Header name={'房屋查询'} />
      <div style={{ padding: 10 }}>
        <div style={{ textAlign: 'center', marginTop: '20%' }}>
          <img
            onClick={() => goCqcx()}
            width={'96%'}
            src={cqcx}
            alt={'暂无图片'}
          />
        </div>
        {/*<div style={{ textAlign: 'center', marginTop: 20 }}>*/}
        {/*  <img*/}
        {/*    onClick={() => getFaceUrl('face_fwtc')}*/}
        {/*    width={'96%'}*/}
        {/*    src={fwtc}*/}
        {/*    alt={'暂无图片'}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div style={{ textAlign: 'center', marginTop: 20 }}>*/}
        {/*  <img width={'96%'} src={zmhy} alt={'暂无图片'} />*/}
        {/*</div>*/}
        <Mask
          color="white"
          visible={visible}
          onMaskClick={() => setVisible(false)}
        />
        <Popup
          position="right"
          visible={visibleCloseRight}
          bodyStyle={{ width: '100%', height: '100vh', overflow: 'auto' }}
          onClose={() => {
            setVisibleCloseRight(false);
          }}
        >
          <div style={{ padding: '20px 10px 10px 10px' }}>
            {/*<h3 style={{ color: 'red' }}>*/}
            {/*  红河州自然资源和规划局关于设立不动产登记监督投诉机制的通告*/}
            {/*</h3>*/}
            <h3
              style={{ marginTop: '10%', width: '100%', textAlign: 'center' }}
            >
              温馨提示
            </h3>
            <div
              style={{
                textIndent: '2em',
                marginTop: 4,
                fontSize: 16,
              }}
            >
              1.该查询记录指红河州依法已登记的不动产登记档案记载信息;查询信息结果与不动产登记簿信息不一致的，以登记簿记载信息为准，申请人应当核实以上身份信息和结果信息，如有误及时告知工作人员。
            </div>
            <div
              style={{
                textIndent: '2em',
                marginTop: 4,
                fontSize: 16,
              }}
            >
              2.查询结果涉及申请人名下不动产登记信息及个人信息，请申请人妥善保管，因申请人保管不善所造成的个人隐私泄露本单位不承担相关法律责任
            </div>
            <div style={{ textAlign: 'center', marginTop: 20 }}>
              <Button
                color={'primary'}
                onClick={() => setVisibleCloseRight(false)}
              >
                继续查询
              </Button>
            </div>
            <Footer />
          </div>
        </Popup>
      </div>
    </>
  );
}
