import React, { useEffect, useState } from 'react';
import Header from '../../common/Header';
import { Button, Form, Grid, Input, Radio, Space, TextArea } from 'antd-mobile';
import { request } from '../../util/request';
import { FormInstance } from 'antd-mobile/es/components/form';

interface Xwbl {
  ywlsh: string;
  zsys: string;
  gyfw: string;
  gyfs: string;
  gybl: string;
  czfs: string;
  dflz: string;
  sflz: string;
  qtsx: string;
}

export interface Business {
  type: string;
  submit: string;
}

function Xwbl(props: any) {
  const { ywlsh } = props.match.params;
  const [loading, setLoading] = useState(false);
  const [gyfs, setGyfs] = React.useState(false);
  const [gybl, setGybl] = React.useState(false);
  const [edit, setEdit] = useState(true);
  const [business, setBusiness] = React.useState<Business>();
  const formRef = React.createRef<FormInstance>();

  const getXwbl = () => {
    request.get<Xwbl>(`/inquiry/getXwbl/${ywlsh}`).then((data) => {
      // 设置表单值
      if (data.gyfw === 'y') {
        setGyfs(true);
      }
      if (data.gyfs === 'n') {
        setGybl(true);
      }
      formRef.current?.setFieldsValue(data);
    });
  };

  const getBusiness = () => {
    request.get<Business>(`/inquiry/getBusiness/${ywlsh}`).then((data) => {
      setEdit(data.submit === 'y');
      setBusiness(data);
    });
  };

  useEffect(() => {
    getXwbl();
    getBusiness();
  }, []);

  const onFinish = (values: any) => {
    setLoading(true);
    values.ywlsh = ywlsh;
    request
      .post<any>('/inquiry/xwblSave', values)
      .then((data) => {
        window.location.href = '#/success';
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Header name={'询问笔录'} />
      <div>
        <Form
          name="form"
          ref={formRef}
          disabled={edit}
          onFinish={onFinish}
          footer={
            <Grid columns={2} gap={12}>
              <Grid.Item>
                <Button
                  block
                  color="primary"
                  size="large"
                  onClick={() =>
                    (window.location.href =
                      business?.type === 'buy'
                        ? `/#/zsxx/${ywlsh}`
                        : `/#/sqr/${ywlsh}`)
                  }
                >
                  上一步
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Button
                  disabled={edit}
                  block
                  type="submit"
                  color="primary"
                  size="large"
                  loading={loading}
                >
                  提交
                </Button>
              </Grid.Item>
            </Grid>
          }
        >
          <Form.Header>请认真如实填写下列问题</Form.Header>
          <Form.Item
            name="zsys"
            label="1.是否为真实意思表示（必填）？"
            rules={[{ required: true, message: '请选择是否为真实意思表示' }]}
          >
            <Radio.Group>
              <Space>
                <Radio value="y">是</Radio>
                <Radio value="n">否</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="gyfw"
            label="2.是否为共有房屋（必填）？"
            rules={[{ required: true, message: '请选择是否为共有房屋' }]}
          >
            <Radio.Group onChange={(val) => setGyfs(val === 'y')}>
              <Space>
                <Radio value="y">是</Radio>
                <Radio value="n">否</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          {gyfs ? (
            <Form.Item
              name="gyfs"
              label="共有方式"
              rules={[{ required: true, message: '请选择共有方式' }]}
            >
              <Radio.Group onChange={(val) => setGybl(val === 'n')}>
                <Space>
                  <Radio value="y">共同共有</Radio>
                  <Radio value="n">按份共有</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          ) : null}
          {gybl ? (
            <Form.Item
              name="gybl"
              label="共有比例，如：张三80%，李四20%"
              rules={[{ required: true, message: '请输入共有比例' }]}
            >
              <TextArea
                placeholder="共有比例"
                autoSize={{ minRows: 2, maxRows: 4 }}
              />
            </Form.Item>
          ) : null}
          <Form.Item
            name="czfs"
            label="3.是否分别持证（必填）？"
            rules={[{ required: true, message: '请选择是否是否分别持证' }]}
          >
            <Radio.Group>
              <Space>
                <Radio value="y">是</Radio>
                <Radio value="n">否</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="dflz" label="4.买方单方领证（选填）：">
            <Radio.Group>
              <Space>
                <Radio value="y">是</Radio>
                <Radio value="n">否</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="sflz" label="5.买卖双方领证（选填）：">
            <Radio.Group>
              <Space>
                <Radio value="y">是</Radio>
                <Radio value="n">否</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="yydj" label="6.不动产存在异议登记：">
            <Radio.Group>
              <Space>
                <Radio value="y">是</Radio>
                <Radio value="n">否</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="qtsx" label="7.其他询问事项（选填）：">
            <Input placeholder="请输入其他询问事项" />
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

export default Xwbl;
