import Header from './Header';
import { getUrlObj } from '../util/url';
import { useEffect, useState } from 'react';
import { request } from '../util/request';
import { Result } from 'antd-mobile';

export default function FaceResult() {
  const urlObj = getUrlObj();
  const [show, setShow] = useState(false);

  useEffect(() => {
    request
      .get(`/faceAuth/callback/${urlObj.BizToken}`)
      .then((data) => {
        // 跳转到首页
        setShow(true);
      })
      .finally(() => {});
  }, []);
  return (
    <>
      <Header name={'认证结果'} />
      {show ? (
        <Result
          status="success"
          title="操作成功"
          description="人脸识别成功，您已通过认证"
        />
      ) : (
        <Result
          status="waiting"
          title="等待处理"
          description="人脸识别正在处理，请耐心等待认证结果"
        />
      )}
    </>
  );
}
