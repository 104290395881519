import Header from '../common/Header';
import { Button, List } from 'antd-mobile';
import Footer from '../common/Footer';

const { useHistory } = require('react-router-dom');
export default function House() {
  const history = useHistory();
  return (
    <>
      <Header name={'房屋查询'} />
      <div style={{ padding: 10, paddingTop: '26%' }}>
        <Button
          style={{ height: 80 }}
          block
          color="primary"
          size="large"
          onClick={() => history.push({ pathname: '/houseInfo' })}
        >
          房屋查询
        </Button>
        <Button
          style={{ marginTop: 10, height: 80 }}
          block
          color="primary"
          size="large"
          onClick={() => history.push({ pathname: '/yfwfzm' })}
        >
          有房无房证明
        </Button>
      </div>
      <Footer />
    </>
  );
}
