import React, { useEffect, useState } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { AddOutline } from 'antd-mobile-icons';
import {
  Button,
  Card,
  ErrorBlock,
  Form,
  Input,
  Modal,
  Popup,
} from 'antd-mobile';
import { request } from '../util/request';

interface Chapter {
  id: string;
  name: string;
  phone: string;
  code: string;
}

function Chapter() {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chapter, setChapter] = useState<Chapter[]>();
  const onFinish = (values: Chapter) => {
    setLoading(true);
    request
      .post<any>('/signature/saveOrUpdate', values)
      .then(() => {
        setVisible(false);
        requestChapter();
      })
      .finally(() => setLoading(false));
  };

  const deleteChapter = (id: string) => {
    setLoading(true);
    request
      .delete(`/signature/remove/${id}`)
      .then(() => {
        requestChapter();
      })
      .finally(() => setLoading(false));
  };
  const requestChapter = () => {
    setLoading(true);
    request
      .get<Chapter[]>('/signature/list')
      .then((data) => {
        setChapter(data);
      })
      .finally(() => setLoading(false));
  };
  const createChapter = (id: string) => {
    setLoading(true);
    request
      .get<string>(`/signature/createChapter/${id}`)
      .then((data) => {
        // Toast.show({ content: data, duration: 2000 });
        window.location.href = data;
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    requestChapter();
  }, []);
  return (
    <div style={{ position: 'relative' }}>
      <Header name={'企业签章授权'} />
      <div>
        <AddOutline
          fontSize={26}
          onClick={() => setVisible(true)}
          style={{ position: 'absolute', top: 8, right: 20 }}
        />
        {chapter?.length === 0 ? (
          <ErrorBlock status="empty" />
        ) : (
          chapter?.map((item, index) => (
            <Card title={'签章' + (index + 1)} style={{ marginTop: 10 }}>
              <div>机构名称:{item.name}</div>
              <div>机构代码:{item.code}</div>
              <div>法人电话:{item.phone}</div>
              <div style={{ textAlign: 'right' }}>
                <Button
                  size={'small'}
                  onClick={async () => {
                    const result = await Modal.confirm({
                      content: '你确定要删除吗？',
                    });
                    if (result) {
                      deleteChapter(item.id);
                    }
                  }}
                >
                  删除
                </Button>
                <Button
                  style={{ marginLeft: 16 }}
                  size={'small'}
                  loading={loading}
                  color="primary"
                  onClick={() => createChapter(item.id)}
                >
                  授权
                </Button>
              </div>
            </Card>
          ))
        )}
        <Popup
          visible={visible}
          onMaskClick={() => {
            setVisible(false);
          }}
          bodyStyle={{ height: '60vh' }}
        >
          <Form
            onFinish={onFinish}
            footer={
              <Button
                loading={loading}
                block
                type="submit"
                color="primary"
                size="large"
              >
                提交
              </Button>
            }
          >
            <Form.Header>签章信息</Form.Header>
            <Form.Item style={{ display: 'none' }} name="id">
              <Input />
            </Form.Item>
            <Form.Item
              name="name"
              label="企业名称"
              rules={[{ required: true, message: '企业名称为空' }]}
            >
              <Input placeholder="请输入企业名称" />
            </Form.Item>
            <Form.Item
              name="code"
              label="机构代码"
              rules={[{ required: true, message: '机构代码不能为空' }]}
            >
              <Input placeholder="请输入机构代码" />
            </Form.Item>
            <Form.Item
              name="phone"
              label="法人手机号"
              rules={[
                { required: true, message: '手机号不能为空', min: 11, max: 11 },
              ]}
            >
              <Input placeholder="请输入手机号" />
            </Form.Item>
          </Form>
        </Popup>
        <Footer />
      </div>
    </div>
  );
}

export default Chapter;
