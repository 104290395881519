import React, { useState, useEffect } from 'react';
import Header from '../../common/Header';
import SelectRecordList from './SelectRecordList';
import {
  Card,
  Button,
  Popup,
  Form,
  Input,
  Divider,
  Selector,
  ImageViewer,
  Image,
  Checkbox,
  Dialog,
  Space,
} from 'antd-mobile';
import './Ywgl.css';
import { UserResponse } from '../../common/PersonalCenter';
import { requestEstate } from '../../util/requestEstate';
import Stroage from '../../util/Stroage';
import { FormInstance } from 'antd-mobile/es/components/form';

interface Record {
  id: string;
  createBy: string;
  createTime: string;
  updateBy: string | null;
  updateTime: string | null;
  sysOrgCode: string;
  zl: string;
  qzh: string;
  mj: string;
  qlr: string;
  status: string;
  sqrmc: string;
  sqrzjh: string;
  fileCount: number;
  dyType: string;
  qhdm: string | null;
  msg: string;
}

export interface SelectRecord {
  dyType: string;
  mj: string;
  qhdm: string;
  qlr: string;
  qzh: string;
  zl: string;
}

interface Options {
  label: string;
  value: string;
}

interface ImageItem {
  id: string;
  url: string;
}

export default function Ywgl() {
  const [data, setData] = useState<Record[]>([]);
  const [selectRecord, setSelectRecord] = useState<SelectRecord[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formData, setFormData] = useState<Record | null>(null);
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const [detailsData, setDetailsData] = useState<Record | null>(null);
  const [isFilePreviewVisible, setIsFilePreviewVisible] = useState(false);
  const [filePreviewData, setFilePreviewData] = useState<Record | null>(null);
  const [xzqhOptions, setXzqhOptions] = useState<Options[]>([]);
  const [dyTypeOptions, setDyTypeOptions] = useState<Options[]>([]);
  const [status, setStatus] = useState<Options[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [images, setImages] = useState<ImageItem[]>([]);
  const [value, setValue] = useState<string[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [selectDjDyVisible, setSelectDjDyVisible] = useState(false);
  const [showImg, setShowImg] = useState(false);
  const [currImg, setCurrImg] = useState('');
  const [currRecord, setCurrRecord] = useState<Record>();
  const formRef = React.createRef<FormInstance>();
  const formRefQuery = React.createRef<FormInstance>();
  const [queryLoading, setQueryLoading] = useState(false);
  const [directoryId, setDirectoryId] = useState('');

  const handleSelect = (record: SelectRecord) => {
    requestEstate
      .post<any>('/qj_ywgl/qjYwgl/add', record)
      .then((res) => {
        if (res && res.result) {
          setIsModalVisible(false);
          fetchData(currentPage);
        }
      })
      .finally(() => setSelectDjDyVisible(false));
    console.log('Selected record:', record);
  };

  const handleImgEditClick = () => {
    setValue([]);
    setIsEditing(!isEditing);
  };

  useEffect(() => {
    const userRes =
      localStorage.getItem('user') &&
      (JSON.parse(localStorage.getItem('user')!) as UserResponse);
    if (!userRes) {
      alert('请登录');
    } else if (userRes.faceAuth !== 'yes') {
      alert('请进行实名认证');
    } else {
      requestEstate.post<any>('/sys/wxLogin').then((res) => {
        if (res.code === 200 && res.result) {
          if (res.result.token) {
            if (res.result.sysAllDictItems) {
              if (res.result.sysAllDictItems.XZQH) {
                setXzqhOptions(res.result.sysAllDictItems.XZQH);
              }
              if (res.result.sysAllDictItems.QJ_DY_TYPE) {
                setDyTypeOptions(res.result.sysAllDictItems.QJ_DY_TYPE);
              }
              if (res.result.sysAllDictItems.CQCX_STATUS) {
                setStatus(res.result.sysAllDictItems.CQCX_STATUS);
              }
            }
            Stroage.setStroage('token', res.result.token);
            fetchData(currentPage);
          }
        }
      });
    }
  }, [currentPage]);

  const statueLabel = (key: string) => {
    debugger;
    let value = status.find((item) => key === item.value)?.label;
    return value === undefined ? '' : value;
  };
  const xzqhLabel = (key: string) => {
    let value = xzqhOptions.find((item) => key === item.value)?.label;
    return value === undefined ? '' : value;
  };
  const dyTypeLabel = (key: string) => {
    let value = dyTypeOptions.find((item) => key === item.value)?.label;
    return value === undefined ? '' : value;
  };

  const fetchData = async (page: number) => {
    try {
      requestEstate
        .get<any>(`/qj_ywgl/qjYwgl/list?pageNo=${page}`)
        .then((res) => {
          console.log(res);
          setData(res.result.records);
          setTotalPages(res.result.pages);
        });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleDeleteImg = () => {
    // 删除确认
    requestEstate
      .delete<any>(`/file/bdcFile/deleteBatch?ids=${value.join(',')}`)
      .then((res) => {
        if (res.code === 200) {
          Dialog.alert({
            content: '删除成功',
          });
          setValue([]);
          if (currRecord) {
            handleFilePreviewClick(currRecord);
          }
          fetchData(currentPage);
        }
      });
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleEditClick = (record: Record) => {
    formRef.current?.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const handleDeleteClick = (record: Record) => {
    Dialog.confirm({
      content: '是否要删除该记录？',
    }).then((result) => {
      if (result) {
        requestEstate.delete('/qj_ywgl/qjYwgl/delete', record).then((res) => {
          setCurrentPage(1);
          fetchData(currentPage);
        });
      }
    });
  };

  const handleQuery = (values: any) => {
    let data = {
      zl: '',
      qzh: '',
      dyType: '',
      qhdm: '',
    };
    // 判断values.qhdm[0]是不是数组
    if (values.qhdm instanceof Array) {
      data.qhdm = values.qhdm[0];
    } else {
      data.qhdm = values.qhdm;
    }
    if (values.dyType instanceof Array) {
      data.dyType = values.dyType[0];
    } else {
      data.dyType = values.dyType;
    }
    if (values.zl) {
      data.zl = values.zl;
    }
    if (values.qzh) {
      data.qzh = values.qzh;
    }
    setQueryLoading(true);
    requestEstate
      .post<any>('/qj_ywgl/qjYwgl/selectDjDy', data)
      .then((res) => {
        if (res && res.result) {
          setSelectRecord(res.result);
        }
      })
      .finally(() => setQueryLoading(false));
  };

  const handleFormSubmit = (values: any) => {
    let data = values;
    // 判断values.qhdm[0]是不是数组
    if (values.qhdm instanceof Array) {
      data.qhdm = values.qhdm[0];
    } else {
      data.qhdm = values.qhdm;
    }
    if (values.dyType instanceof Array) {
      data.dyType = values.dyType[0];
    } else {
      data.dyType = values.dyType;
    }
    if (values.id) {
      requestEstate.put<any>('/qj_ywgl/qjYwgl/edit', data).then((res) => {
        if (res && res.result) {
          setIsModalVisible(false);
          fetchData(currentPage);
        }
      });
    } else {
      requestEstate.post<any>('/qj_ywgl/qjYwgl/add', data).then((res) => {
        if (res && res.result) {
          setIsModalVisible(false);
          fetchData(currentPage);
        }
      });
    }
  };

  const handleDetailsClick = (record: Record) => {
    setDetailsData(record);
    setIsDetailsVisible(true);
  };

  const handleFilePreviewClick = (record: Record) => {
    setCurrRecord(record);
    requestEstate
      .get<any>(`/qj_ywgl/qjYwgl/queryUrlList/${record.id}`)
      .then((res) => {
        if (res && res.result) {
          setImages(res.result);
        }
      });
    setFilePreviewData(record);
    setIsFilePreviewVisible(true);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const formData = new FormData();
      formData.append('file', event.target.files[0]);
      setLoading(true);
      requestEstate
        .post(
          `/file/bdcFile/qj/upload/${directoryId}`,
          { file: event.target.files[0] },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        .then((response) => {
          console.log('File uploaded successfully:', response);
          fetchData(currentPage);
        })
        .catch((error) => {
          console.error('Error uploading file:', error);
          // Handle error
        })
        .finally(() => {
          // setSelectedFile(null);
          setLoading(false);
        });
    }
  };

  const handleSelectDjDy = () => {
    setSelectRecord([]);
    formRefQuery.current?.resetFields();
    setSelectDjDyVisible(true);
  };

  const handleFileUpload = (recordId: string) => {
    setDirectoryId(recordId);
  };

  return (
    <div className="container">
      <Header name={'业务关联'} />
      <Space wrap style={{ marginBottom: 6, marginTop: 6 }}>
        <Button
          onClick={() => {
            formRef.current?.resetFields();
            setIsModalVisible(true);
          }}
          color="primary"
          fill="solid"
          size="small"
        >
          新增单元
        </Button>
        <Button
          color="primary"
          fill="solid"
          size="small"
          onClick={handleSelectDjDy}
        >
          选择单元
        </Button>
      </Space>
      {data.map((record) => (
        <Card
          title={'坐落：' + record.zl}
          key={record.id}
          className="card-item"
        >
          <div>
            <div>
              <span>{'不动产权证号：' + record.qzh}</span>
              <Divider direction="vertical" />
              <span>{'权利人：' + record.qlr}</span>
              <Divider direction="vertical" />
              <span>{'面积：' + record.mj}平米</span>
              <Divider direction="vertical" />
              <span>{'状态：' + statueLabel(record.status)}</span>
              {record.msg !== null || record.msg !== '' ? (
                <>
                  <Divider direction="vertical" />
                  <span>{'单元类型：' + dyTypeLabel(record.dyType)}</span>
                </>
              ) : null}
              <Divider direction="vertical" />
              <span>{'单元类型：' + dyTypeLabel(record.dyType)}</span>
            </div>
          </div>
          <div className="buttons">
            <Button
              size="small"
              fill="outline"
              color="primary"
              className="button"
              onClick={() => handleFilePreviewClick(record)}
            >
              文件预览({record.fileCount})
            </Button>
            <input
              type="file"
              id={`fileInput-${record.id}`}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            {record.status !== '1' ? (
              <Button
                size="small"
                fill="outline"
                color="primary"
                className="button"
                loading={loading}
                onClick={async () => {
                  const fileInput = document.getElementById(
                    `fileInput-${record.id}`
                  );
                  if (fileInput) {
                    fileInput.click();
                    await new Promise((resolve) => {
                      fileInput.addEventListener('change', resolve, {
                        once: true,
                      });
                    });
                    handleFileUpload(record.id);
                  }
                }}
              >
                文件上传
              </Button>
            ) : null}
            {record.status !== '1' ? (
              <Button
                size="small"
                fill="outline"
                color="primary"
                className="button"
                onClick={() => handleEditClick(record)}
              >
                编辑
              </Button>
            ) : null}
            {record.status !== '1' ? (
              <Button
                size="small"
                fill="outline"
                color="danger"
                className="button"
                onClick={() => handleDeleteClick(record)}
              >
                删除
              </Button>
            ) : null}
            {record.status === '1' ? (
              <Button
                size="small"
                fill="outline"
                color="primary"
                className="button"
                onClick={() => handleDetailsClick(record)}
              >
                详情
              </Button>
            ) : null}
          </div>
        </Card>
      ))}
      <div className="pagination">
        <span>当前页: {currentPage}</span>
        <span>总页数: {totalPages}</span>
        <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
          上一页
        </Button>
        <Button onClick={handleNextPage} disabled={currentPage === totalPages}>
          下一页
        </Button>
      </div>
      <Popup
        visible={isModalVisible}
        bodyStyle={{ height: '100vh' }}
        onMaskClick={() => setIsModalVisible(false)}
      >
        <div style={{ padding: '20px', height: '90vh', overflowY: 'scroll' }}>
          <Form
            ref={formRef}
            initialValues={formData || {}}
            onFinish={handleFormSubmit}
            footer={
              <div>
                <Button block type="submit" color="primary" size="large">
                  提交
                </Button>
                <Button
                  block
                  onClick={() => setIsModalVisible(false)}
                  style={{ marginTop: 10 }}
                  size="large"
                >
                  取消
                </Button>
              </div>
            }
          >
            <Form.Item style={{ display: 'none' }} name="id">
              <Input />
            </Form.Item>
            <Form.Item
              name="zl"
              label="坐落"
              rules={[{ required: true, message: '坐落是必填项' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="qzh"
              label="不动产权证号"
              rules={[{ required: true, message: '不动产权证号是必填项' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="mj"
              label="面积"
              rules={[{ required: true, message: '面积是必填项' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="qlr"
              label="权利人"
              rules={[{ required: true, message: '权利人是必填项' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="dyType"
              label="单元类型"
              rules={[{ required: true, message: '单元类型是必填项' }]}
            >
              <Selector options={dyTypeOptions} />
            </Form.Item>
            <Form.Item
              name="qhdm"
              label="办理区域"
              rules={[{ required: true, message: '办理区域是必填项' }]}
            >
              <Selector options={xzqhOptions} />
            </Form.Item>
          </Form>
        </div>
      </Popup>
      <Popup
        visible={isDetailsVisible}
        onMaskClick={() => setIsDetailsVisible(false)}
      >
        <div style={{ padding: '20px' }}>
          {detailsData && (
            <div>
              <p>坐落: {detailsData.zl}</p>
              <p>权证号: {detailsData.qzh}</p>
              <p>面积: {detailsData.mj}</p>
              <p>权利人: {detailsData.qlr}</p>
              <p>单元类型: {dyTypeLabel(detailsData.dyType)}</p>
              <p>状态: {statueLabel(detailsData.status)}</p>
              <p>权利人名称: {detailsData.sqrmc}</p>
              <p>权利人证件号: {detailsData.sqrzjh}</p>
              <p>消息通知: {detailsData.msg}</p>
              <p>申请时间: {detailsData.createTime}</p>
            </div>
          )}
        </div>
      </Popup>
      <Popup
        showCloseButton={true}
        onClose={() => setIsFilePreviewVisible(false)}
        bodyStyle={{ height: '100vh' }}
        visible={isFilePreviewVisible}
        onMaskClick={() => setIsFilePreviewVisible(false)}
      >
        <div style={{ padding: '20px' }}>
          {filePreviewData && (
            <div>
              <p>文件预览: {filePreviewData.fileCount} 个文件</p>
              <div className="button-container">
                <Button
                  className="button button-primary"
                  onClick={handleImgEditClick}
                >
                  {isEditing ? '预览' : '编辑'}
                </Button>
                <Button
                  className="button button-danger"
                  disabled={value.length === 0}
                  onClick={async () => {
                    const result = await Dialog.confirm({
                      content: '是否要删除选中图片？',
                    });
                    if (result) {
                      handleDeleteImg();
                    }
                  }}
                >
                  删除
                </Button>
              </div>
              {images.length === 0 ? (
                <div className="no-data-message">暂无数据</div>
              ) : null}
              {!isEditing ? (
                <div className="checkbox-container">
                  {images.map((image) => (
                    <Image
                      onClick={() => {
                        setShowImg(true);
                        setCurrImg(image.url);
                      }}
                      key={image.id}
                      className="checkbox-item"
                      src={image.url}
                      width={80}
                      height={80}
                      fit="cover"
                      style={{ borderRadius: 4 }}
                    />
                  ))}
                </div>
              ) : (
                <div className="checkbox-container">
                  <Checkbox.Group
                    value={value}
                    onChange={(v) => {
                      setValue(v as string[]);
                    }}
                  >
                    {images.map((image) => (
                      <Checkbox
                        className="checkbox-item"
                        key={image.id}
                        value={image.id}
                      >
                        <Image
                          src={image.url}
                          width={80}
                          height={80}
                          fit="cover"
                          style={{ borderRadius: 4 }}
                        />
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </div>
              )}
            </div>
          )}
        </div>
        <ImageViewer
          image={currImg}
          visible={showImg}
          onClose={() => {
            setShowImg(false);
          }}
        />
      </Popup>

      <Popup
        showCloseButton={true}
        onClose={() => setSelectDjDyVisible(false)}
        bodyStyle={{ height: '100vh' }}
        visible={selectDjDyVisible}
        onMaskClick={() => setSelectDjDyVisible(false)}
      >
        <div style={{ padding: '20px' }}>
          <Form
            ref={formRefQuery}
            initialValues={formData || {}}
            onFinish={handleQuery}
            footer={
              <div>
                <Button
                  block
                  loading={queryLoading}
                  type="submit"
                  color="primary"
                  size="large"
                >
                  提交
                </Button>
                <Button
                  block
                  style={{ marginTop: 10 }}
                  size="large"
                  onClick={() => setSelectDjDyVisible(false)}
                >
                  取消
                </Button>
              </div>
            }
          >
            <Form.Item name="zl" label="坐落">
              <Input />
            </Form.Item>
            <Form.Item name="qzh" label="不动产权证号">
              <Input />
            </Form.Item>
            <Form.Item
              name="dyType"
              label="单元类型"
              rules={[{ required: true, message: '单元类型是必填项' }]}
            >
              <Selector options={dyTypeOptions} />
            </Form.Item>
            <Form.Item
              name="qhdm"
              label="办理区域"
              rules={[{ required: true, message: '办理区域是必填项' }]}
            >
              <Selector options={xzqhOptions} />
            </Form.Item>
          </Form>
        </div>
        {selectRecord.length === 0 ? (
          <div className="no-data-message">暂时数据</div>
        ) : null}
        {selectRecord.length > 0 ? (
          <SelectRecordList
            selectRecord={selectRecord}
            onSelect={handleSelect}
            xzqhLabel={xzqhLabel}
            dyTypeLabel={dyTypeLabel}
          />
        ) : null}
      </Popup>
    </div>
  );
}
