import Header from './../common/Header';
import Footer from './../common/Footer';
import { UserResponse } from './../common/PersonalCenter';
import {
  Empty,
  Button,
  Popup,
  Form,
  Input,
  Card,
  Grid,
  Modal,
} from 'antd-mobile';
import { AddOutline } from 'antd-mobile-icons';
import { useEffect, useState } from 'react';
import { request } from '../util/request';
import { FormInstance } from 'antd-mobile/es/components/form';

interface Family {
  id: string;
  name: string;
  phone: string;
  idCard: string;
  openid: string;
  oldName: string;
  oldIdCard: string;
}

export default function Jt() {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [family, setFamily] = useState<Family[]>();
  const [form] = Form.useForm();
  const familyList = () => {
    setLoading(true);
    request
      .get<Family[]>('/family/list')
      .then((data) => {
        setFamily(data);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    familyList();
  }, []);
  const onFinish = (values: Family) => {
    setLoading(true);
    request
      .post('/family/save', values)
      .then(() => {
        setVisible(false);
        familyList();
      })
      .finally(() => setLoading(false));
  };

  const deleteFamily = (id: string) => {
    setLoading(true);
    request
      .delete(`/family/delete/${id}`)
      .then(() => {
        familyList();
      })
      .finally(() => setLoading(false));
  };

  const inputChangeCode = (code: string) => {
    if (code.length === 12) {
      request.get<UserResponse>(`/cxsq/getUserByCode/${code}`).then((data) => {
        form.setFieldsValue({
          name: data.name,
          idCard: data.idCard,
          phone: data.phone,
        });
        setVisible(true);
      });
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <Header name={'家庭成员'} />
      <AddOutline
        fontSize={26}
        onClick={() => setVisible(true)}
        style={{ position: 'absolute', top: 8, right: 20 }}
      />
      {family?.length === 0 ? <Empty description="暂无数据" /> : null}
      {family?.map((item, index) => (
        <Card title={'家庭成员' + (index + 1)} style={{ marginTop: 10 }}>
          <Grid columns={3} gap={8}>
            <Grid.Item span={2}>
              <div>{item.name}</div>
              <div>{item.idCard}</div>
              {/*<div style={{ marginTop: 10 }}>曾用名：{item.oldName}</div>*/}
              {/*<div>曾用证件号：{item.oldIdCard}</div>*/}
            </Grid.Item>
            <Grid.Item>
              <div
                style={{ marginTop: 4, textAlign: 'right', marginRight: '12%' }}
              >
                <Button
                  size="small"
                  color="danger"
                  onClick={async () => {
                    const result = await Modal.confirm({
                      content: '是否确定删除！',
                    });
                    if (result) {
                      deleteFamily(item.id);
                    }
                  }}
                >
                  删除
                </Button>
              </div>
            </Grid.Item>
          </Grid>
        </Card>
      ))}
      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        bodyStyle={{ height: '85vh', overflowY: 'scroll', marginBottom: 10 }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          footer={
            <Button
              loading={loading}
              block
              type="submit"
              color="primary"
              size="large"
            >
              提交
            </Button>
          }
        >
          <Form.Header>添加家庭成员</Form.Header>
          <Form.Item
            name="name"
            label="姓名"
            rules={[{ required: true, message: '姓名不能为空' }]}
          >
            <Input disabled={true} placeholder="请输入姓名" />
          </Form.Item>
          <Form.Item
            name="idCard"
            label="证件号"
            rules={[
              { required: true, message: '证件号为18位', min: 18, max: 18 },
            ]}
          >
            <Input disabled={true} placeholder="请输入证件号" />
          </Form.Item>
          <Form.Item
            name="phone"
            label="手机号"
            rules={[
              { required: true, message: '手机号不能为空', min: 11, max: 11 },
            ]}
          >
            <Input disabled={true} placeholder="请输入手机号" />
          </Form.Item>
          {/*<Form.Item name="oldName" label="曾用名" help="户口本的上的曾用名">*/}
          {/*  <Input placeholder="请输入地址" />*/}
          {/*</Form.Item>*/}
          {/*<Form.Item*/}
          {/*  name="oldIdCard"*/}
          {/*  label="曾用证件号"*/}
          {/*  help="包含之前使用的15位身份证号码"*/}
          {/*>*/}
          {/*  <Input placeholder="请输入地址" />*/}
          {/*</Form.Item>*/}
          <Form.Item
            name="code"
            label="授权码"
            help="18岁及以上的成员需要填写授权码"
          >
            <Input
              placeholder="18岁及以上的成员需要填写授权码"
              onChange={inputChangeCode}
            />
          </Form.Item>
        </Form>
      </Popup>
      <Footer />
    </div>
  );
}
