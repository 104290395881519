import React, {useEffect, useRef, useState} from 'react';
import Header from "../../common/Header";
import {requestEstate} from "../../util/requestEstate";
import {DotLoading, Toast, SearchBar, Modal} from "antd-mobile";
import {Pagination} from "antd";
import './Bszn.css';

interface Guide {
	id: string,
	title: string,
	content: string,
	source: string,
	publishTime: string
}

function Bszn() {
	const [guideList, setGuideList] = useState<Array<Guide>>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const pagination = useRef<{
		current: number,//当前页,从1开始
		size: number,//每页数据个数
		total: number,//数据总数
	}>({
		current: 1,
		size: 10,
		total: 0
	});
	const modalRef = useRef(null);

	//获取数据列表
	async function getList(value?: string) {
		setLoading(true);
		try {
			const res = await requestEstate.get<{
				result: {
					records: Array<Guide>,
					pages: number,
					size: number,
					total: number,
					current: number
				}
			}>('/publish_content/publishContent/list', {
				type: '3',
				title: value,
				pageSize: pagination.current.size,
				pageNo: value ? 1 : pagination.current.current
			});
			setGuideList(res.result.records);
			pagination.current.current = res.result.current;
			pagination.current.size = res.result.size;
			pagination.current.total = res.result.total;
		} catch (e) {
			console.log(e);
			Toast.show({icon: 'fail', content: '网络错误', duration: 5000});
		}
		setLoading(false);
	}

	//切换页码
	async function paginationChange(page: number, pageSize: number) {
		pagination.current.current = page;
		await getList();
	}

	//显示详情弹窗
	function showDetail(guide: Guide) {
		// guide.content = guide.content.replaceAll('<img', '<img style="width:100%"');
		Modal.alert({
			title: guide.title,
			content: <div dangerouslySetInnerHTML={{__html: guide.content}}></div>,
			closeOnMaskClick: true,
			getContainer: modalRef.current
		});
	}

	useEffect(() => {
		getList().then();
	}, []);
	return (
		<div className={'bszn-page'}>
			<Header name={'办事指南'}/>
			<div className={'page-content'}>
				<div style={{display: loading ? 'flex' : 'none'}} className='loading'>
					<span>数据加载中</span>
					<DotLoading color='currentColor'/>
				</div>
				<div className={'search-bar'}>
					<SearchBar placeholder='请输入关键词' showCancelButton onSearch={(value) => getList(value)}/>
				</div>
				<div className={'guide-list'}>
					{
						guideList.map(guide => {
							return (
								<div className={'guide-item'} key={guide.id} onClick={() => showDetail(guide)}>
									<div className={'guide-title'}>{guide.title}</div>
									<div className={'guide-tips'}>
										<span>发布时间：{guide.publishTime}</span>
										<span>来源：{guide.source}</span>
									</div>
								</div>
							)
						})
					}
				</div>
				<Pagination style={{display: guideList.length !== 0 ? 'block' : 'none'}} className={'pagination'}
										current={pagination.current.current} total={pagination.current.total} onChange={paginationChange}/>
				<div className='empty' style={{display: guideList.length === 0 && !loading ? 'block' : 'none'}}>
					暂无办事指南数据
				</div>
				<div className={'detail-modal'} ref={modalRef}></div>
			</div>
		</div>
	);
}

export default Bszn;
