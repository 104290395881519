import React, { memo, useState } from 'react';
import { baseURL } from '../util/request';
import { Button } from 'antd-mobile';
import { pdfjs, Document, Page } from 'react-pdf';
// @ts-ignore
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default memo((data: any) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const phoneName = () => {
    const u = navigator.userAgent;
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; // android终端
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
    if (isAndroid) {
      return 'android';
    } else if (isiOS) {
      return 'ios';
    } else {
      return 'other';
    }
  };

  // @ts-ignore
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <p>
        <Button
          style={{ marginLeft: 10, marginRight: 10 }}
          size={'small'}
          color="primary"
          onClick={() => setPageNumber((prevstate) => prevstate - 1)}
          disabled={pageNumber === 1}
        >
          上一页
        </Button>
        <span style={{ fontSize: 14, fontWeight: '600' }}>
          {pageNumber}/{numPages}页
        </span>
        <Button
          style={{ marginLeft: 10 }}
          size={'small'}
          color="primary"
          onClick={() => setPageNumber((prevstate) => prevstate + 1)}
          disabled={pageNumber === numPages}
        >
          下一页
        </Button>
      </p>
      <div style={{ overflow: 'scroll', height: '96vh' }}>
        <Document
          file={data.filePath}
          loading={<div>加载中...</div>}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page
            pageNumber={pageNumber}
            scale={0.68}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </Document>
      </div>
    </div>
  );
});
