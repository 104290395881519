import React from 'react';
import {NavBar} from 'antd-mobile';

const style = {
    flex: 0,
    borderBottom: 'solid 1px var(--adm-color-border)',
};
const {
    useHistory
} = require('react-router-dom');
export default function Header({name, goback}: { name: string, goback?: any }) {
    const history = useHistory();
    return <div style={style}>
        <NavBar back={goback ? '返回' : '首页'} onBack={() => {
            goback ? goback() : history.push({pathname: '/'});
        }}>{name}</NavBar>
    </div>;
}
