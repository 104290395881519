import Header from '../../common/Header';
import fwzt from '../../img/WechatIMG398.png';
import fwtc from '../../img/WechatIMG399.png';
import zmhy from '../../img/zmhy.png';
import { Button, Modal, Popup } from 'antd-mobile';
import { useState } from 'react';
import { ExclamationCircleFill } from 'antd-mobile-icons';

const { useHistory } = require('react-router-dom');

export default function HouseQuery() {
  const history = useHistory();
  const [visible, setVisible] = useState(true);

  return (
    <>
      <Header name={'房屋查询'} />
      <div style={{ padding: 10 }}>
        <div style={{ textAlign: 'center', marginTop: '20%' }}>
          <img
            onClick={() => history.push({ pathname: '/fwzt' })}
            width={'96%'}
            src={fwzt}
            alt={'暂无图片'}
          />
        </div>
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <img
            onClick={() => history.push({ pathname: '/fwtc' })}
            width={'96%'}
            src={fwtc}
            alt={'暂无图片'}
          />
        </div>
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <img
            onClick={() => history.push({ pathname: '/house_check' })}
            width={'96%'}
            src={zmhy}
            alt={'暂无图片'}
          />
        </div>
      </div>
      <Popup
        position="right"
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
      >
        <div style={{ padding: 10 }}>
          <div
            style={{
              marginTop: '30%',
              textAlign: 'center',
              fontSize: 18,
              fontWeight: 'bold',
            }}
          >
            达州市不动产登记信息网络查询须知
          </div>
          <div style={{ marginTop: 20, textIndent: '2em', display: 'block' }}>
            一、网上申请采用实名制查询，申请人须持中华人民共和国居民身份证办理且为本人操作并进行人脸识别方能通过。
          </div>
          <div style={{ marginTop: 20, textIndent: '2em', display: 'block' }}>
            二、查询范围为达州市全域，查询达川区、通川区、宣汉县、开江县、大竹县、渠县、万源市区域内不动产登记信息，请选择对应行政区。
          </div>
          <div style={{ marginTop: 20, textIndent: '2em', display: 'block' }}>
            三、查询结果仅代表实时查询情况，查询人需及时核对查询结果，如您的查询结果与实际不符，请到不动产所属区域登记机构进行人工查询核验。
          </div>
          <div style={{ marginTop: 20, textIndent: '2em', display: 'block' }}>
            四、查询人应妥善保管及正当使用不动产登记信息查询结果，若将查询结果用于不当得利或丢失造成自身经济损失等，查询人需承担相应的法律责任或不良后果。
          </div>
          <div style={{ padding: 30 }}>
            <Button
              block
              color="primary"
              size="large"
              onClick={() => setVisible(false)}
            >
              我同意
            </Button>
          </div>
        </div>
      </Popup>
    </>
  );
}
