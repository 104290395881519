import Header from './../common/Header';
import Footer from './../common/Footer';
import { List } from 'antd-mobile';

export default function SinfJdcx() {
  return (
    <>
      <Header name={'公示公告'} />
      <List header="红河州不动产登记公告合集">
        <List.Item
          onClick={() => {
            window.location.href =
              'http://www.hhgj.gov.cn/zfxxgk/fdzdgknr/zdlyxxgk/gtyzy/bdcdjgg/';
          }}
        >
          个旧市人民政府不动产登记公告
        </List.Item>
        <List.Item
          onClick={() => {
            window.location.href =
              'http://www.hhmz.gov.cn/zfxxgk/fdzdgkzfxx/zdlyxxgk/gtzyxxgk/bdcdjgg/';
          }}
        >
          蒙自市人民政府不动产登记公告
        </List.Item>
        <List.Item
          onClick={() => {
            window.location.href =
              'http://www.hhky.gov.cn/zfxxgk/fdzdgkzfxx/gtzyzwgk/';
          }}
        >
          开远市人民政府不动产登记公告
        </List.Item>
        <List.Item
          onClick={() => {
            window.location.href =
              'http://www.hhpb.gov.cn/zfxxgk/fdzdgkxx/zdlyxxgk/gtzyzwgk/bdcdjgg/';
          }}
        >
          屏边县人民政府不动产登记公告
        </List.Item>
        <List.Item
          onClick={() => {
            window.location.href =
              'http://www.hhjs.gov.cn/zdlyxxgk/gtzyzgk/bdcdjz/';
          }}
        >
          建水县人民政府不动产登记公告
        </List.Item>
        <List.Item
          onClick={() => {
            window.location.href =
              'http://www.hhsp.gov.cn/zfxxgk/gsgg_16053/bdcdjgg_16054/';
          }}
        >
          石屏县人民政府不动产登记公告
        </List.Item>
        <List.Item
          onClick={() => {
            window.location.href =
              'http://www.hhml.gov.cn/zfxxgk_15889/fdzdgknr/zdlyxxgk/bdcdjgg/';
          }}
        >
          弥勒市人民政府不动产登记公告
        </List.Item>
        <List.Item
          onClick={() => {
            window.location.href =
              'http://www.hhlx.gov.cn/zfxxgk/fdzdgknr/zdlyxxgkzl/gtxx/tzgg1/';
          }}
        >
          泸西县人民政府不动产登记公告
        </List.Item>
        <List.Item
          onClick={() => {
            window.location.href =
              'http://www.hhyy.gov.cn/zfxxgk/fdzdgkzfxxhzdlyxx/zdlyxxgk/gtxx/';
          }}
        >
          元阳县人民政府不动产登记公告
        </List.Item>
        <List.Item
          onClick={() => {
            window.location.href =
              'http://www.hhx.gov.cn/zfxxgk/fdzdgkzfxx/zdlyxxgk_1/zrzyzwxxgk/bdcdj/';
          }}
        >
          红河县人民政府不动产登记公告
        </List.Item>
        <List.Item
          onClick={() => {
            window.location.href =
              'http://www.hhjp.gov.cn/zfxxgk/fdzdgkzfxx/zdlyxxgk1/zdxx/bdcgj/';
          }}
        >
          金平县人民政府不动产登记公告
        </List.Item>
        <List.Item
          onClick={() => {
            window.location.href =
              'http://www.hhlc.gov.cn/zfxxgk_16058/fdzdgknr/zdlyxxgk/gtxxgk/';
          }}
        >
          绿春县人民政府不动产登记公告
        </List.Item>
        <List.Item
          onClick={() => {
            window.location.href =
              'http://www.hhhk.gov.cn/zfxxgk/fdzdgkzfxx/zdlyxxgk/zrzyxxgk1/bdcdj1/';
          }}
        >
          河口县人民政府不动产登记公告
        </List.Item>
      </List>
      <Footer />
    </>
  );
}
