import React, { useEffect } from 'react';
import { wx, init } from '../util/WxUtil';
import Header from '../common/Header';
import sm from '../icon/sm.svg';
import { Mask, Popup } from 'antd-mobile';
import PdfView from '../common/PdfView';

function Zmhy() {
  const [visible, setVisible] = React.useState(false);
  const [visible2, setVisible2] = React.useState(false);
  const [fileId, setFileId] = React.useState('');
  useEffect(() => {
    init();
  }, []);
  return (
    <>
      <Header name={'证明核验'} />
      <div
        onClick={() => {
          setVisible2(true);
          wx.ready(() => {
            wx.scanQRCode({
              needResult: 1,
              scanType: ['qrCode', 'barCode'],
              success: function (res: { resultStr: any }) {
                const result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                setFileId(result);
                setVisible(true);
                setVisible2(false);
                // window.location.href = `${baseURL}/file/download/${result}`;
              },
              fail: function (err: any) {
                setVisible2(false);
              },
              cancel: function () {
                setVisible2(false);
              },
            });
          });
        }}
        style={{ width: '100%', textAlign: 'center' }}
      >
        <div>
          <img src={sm} alt="" width={100} style={{ marginTop: '10vh' }} />
        </div>
        <div style={{ color: '#009FE8', fontSize: 16 }}>扫一扫</div>
      </div>
      <Popup
        visible={visible}
        showCloseButton
        onClose={() => {
          setVisible(false);
        }}
      >
        <div style={{ height: '96vh' }}>
          <PdfView fileId={fileId} />
        </div>
      </Popup>
      <Mask color="white" visible={visible2} />
    </>
  );
}

export default Zmhy;
