import Header from '../../common/Header';
import React, { useEffect, useRef, useState } from 'react';
import { wx, init } from '../../util/WxUtil';
import sm from '../../icon/sm.svg';
import { List, Mask, Popup } from 'antd-mobile';
import { request } from '../../util/request';
import QRCode from 'qrcode.react';

interface ZsInfo {
  lx: string;
  bdcqzk: string;
  qlr: string;
  gyqk: string;
  zl: string;
  bdcdyh: string;
  qllx: string;
  qlxz: string;
  yt: string;
  mj: string;
  syqx: string;
  qlqtzk: string;
  fj: string;
  bdczmh: string;
  zmqlhsx: string;
  qt: string;
  zzmlid: string;
  code: string;
}

// {"lx":"zs","bdcqzk":"川(2021)遂宁市不动产权第0039227号","qlr":"向吉平","gyqk":"单独所有","zl":"船山区德水中路418号尚城五期·雅庭地下室-1层283号","bdcdyh":"510903302015GX00006F00020888","qllx":"国有建设用地使用权/房屋（构筑物）所有权","qlxz":"出让/市场化商品房","yt":"城镇住宅用地/其它","mj":"宗地面积：22710㎡/房屋建筑面积 ：31.61㎡","syqx":"国有建设用地使用权 2014年05月20日起2084年05月19日止","qlqtzk":"","fj":"","code":"ff8080817aa3fd55017aa7f0d0cd27b0"}

export default function FhtSn() {
  const [visible, setVisible] = React.useState(false);
  const [url, setUrl] = React.useState('');
  const [zsinfo, setZsinfo] = useState<ZsInfo>();
  const [visibleCloseRight, setVisibleCloseRight] = useState(false);
  useEffect(() => {
    init();
  }, []);
  return (
    <>
      <Header name={'证照核验'} />
      <div
        onClick={() => {
          setVisible(true);
          wx.ready(() => {
            wx.scanQRCode({
              needResult: 1,
              scanType: ['qrCode', 'barCode'],
              success: function (res: { resultStr: any }) {
                // const result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                // const url = res.resultStr.split('&')[0] || '';
                // setUrl(res.resultStr);
                const result = res.resultStr.split(',')[1]; // 当needResult 为 1 时，扫码返回的结果

                // @ts-ignore
                // const xzqdm = cityRef.current && cityRef.current.getData();
                request
                  .post<ZsInfo>('/snQuery/getResultByCid', {
                    cid: res.resultStr,
                    type: 'dzzzewm',
                  })
                  .then((res) => {
                    setZsinfo(res);
                    setVisibleCloseRight(true);
                  })
                  .finally(() => setVisible(false));
              },
              fail: function (err: any) {
                setVisible(false);
              },
              cancel: function () {
                setVisible(false);
              },
            });
          });
        }}
        style={{ width: '100%', textAlign: 'center' }}
      >
        <div>
          <img src={sm} alt="" width={100} style={{ marginTop: '10vh' }} />
        </div>
        <div style={{ color: '#009FE8', fontSize: 16 }}>扫一扫</div>
      </div>
      <Popup
        position="right"
        visible={visibleCloseRight}
        showCloseButton
        onClose={() => {
          setVisibleCloseRight(false);
        }}
      >
        <div style={{ width: '100%' }}></div>
        <div style={{ overflowY: 'auto', height: '96vh', paddingBottom: 50 }}>
          <div
            style={{
              marginTop: '8%',
              textAlign: 'center',
              fontSize: 18,
              fontWeight: 'bold',
            }}
          >
            证照信息
          </div>
          {zsinfo?.lx === 'zs' ? (
            <List header="证书详情" mode={'card'}>
              <List.Item>不动产权证号：{zsinfo?.bdcqzk}</List.Item>
              <List.Item>权利人：{zsinfo?.qlr}</List.Item>
              <List.Item>共有情况：{zsinfo?.gyqk}</List.Item>
              <List.Item>坐落：{zsinfo?.zl}</List.Item>
              <List.Item>不动产单元号：{zsinfo?.bdcdyh}</List.Item>
              <List.Item>权利类型：{zsinfo?.qllx}</List.Item>
              <List.Item>权利性质：{zsinfo?.qlxz}</List.Item>
              <List.Item>用途：{zsinfo?.yt}</List.Item>
              <List.Item>面积：{zsinfo?.mj}</List.Item>
              <List.Item>使用期限：{zsinfo?.syqx}</List.Item>
            </List>
          ) : null}
          {zsinfo?.lx === 'zm' ? (
            <List header="证书详情" mode={'card'}>
              <List.Item>不动产权证号：{zsinfo?.bdczmh}</List.Item>
              <List.Item>权利人：{zsinfo?.qlr}</List.Item>
              <List.Item>义务人：{zsinfo?.qlr}</List.Item>
              <List.Item>证明权利或事项：{zsinfo?.zmqlhsx}</List.Item>
              <List.Item>不动产单元号：{zsinfo?.bdcdyh}</List.Item>
              <List.Item>坐落：{zsinfo?.zl}</List.Item>
              <List.Item>其他：{zsinfo?.qt}</List.Item>
              <List.Item>附记：{zsinfo?.fj}</List.Item>
            </List>
          ) : null}
        </div>
      </Popup>
      <div>
        <Mask color="white" visible={visible} />
      </div>
    </>
  );
}
