import React, { useEffect, useRef, useState } from 'react';
import Header from './../common/Header';
import Footer from './../common/Footer';
import { request } from '../util/request';
import {
  Form,
  Input,
  Button,
  Modal,
  Steps,
  Grid,
  Space,
  Toast,
} from 'antd-mobile';
import City from '../common/City';
import { ScanningOutline } from 'antd-mobile-icons';
import { init, wx } from '../util/WxUtil';
import { FormInstance } from 'antd-mobile/es/components/form';

interface JdcxResponse {
  xmmc: string;
  jdxx: [];
}

const { Step } = Steps;

export default function SinfJdcx() {
  const cityRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormInstance>(null);
  const onFinish = (values: { ywlsh: string }) => {
    // @ts-ignore
    const qhdm = cityRef.current && cityRef.current.getData();
    if (qhdm === undefined || qhdm === null || qhdm === '') {
      Toast.show({
        content: '请选择行政区',
        position: 'top',
      });
      return;
    }
    setLoading(true);
    request
      .get<JdcxResponse>(`/sinfCloud/jdcx/${values.ywlsh}/${qhdm}`)
      .then((res) => {
        let jdxxArr = res.jdxx;
        // 判断是否有数据
        if (res.jdxx && jdxxArr.length >= 2) {
          // @ts-ignore
          let tmp1 = jdxxArr[0];
          // @ts-ignore
          let tmp2 = jdxxArr[1];
          if (
            // @ts-ignore
            tmp1?.actdef_name === '受理初审' &&
            // @ts-ignore
            tmp2?.actdef_name === '收件'
          ) {
            // @ts-ignore
            jdxxArr[0] = tmp2;
            // @ts-ignore
            jdxxArr[1] = tmp1;
          }
        }
        Modal.alert({
          content: (
            <>
              <h4>{res.xmmc}</h4>
              <Steps direction="vertical">
                {jdxxArr.map((item: any) => {
                  return item.actdef_name === null ? null : (
                    <Step
                      key={item.id}
                      status="finish"
                      title={`办理环节：${item.actdef_name}`}
                      description={`办理时间：${item.actinst_start}`}
                    />
                  );
                })}
              </Steps>
            </>
          ),
        });
      })
      .finally(() => setLoading(false));
  };
  // useEffect(() => {
  //   init();
  // }, []);
    const scanQRCode = () => {
        return new Promise((resolve, reject) => {
            wx.scanQRCode({
                needResult: 1,
                scanType: ['qrCode', 'barCode'],
                success: function (res: { resultStr: any }) {
                    const lsh = res.resultStr.split(',')[1];
                    resolve(lsh);
                },
                fail: function (err: any) {
                    reject(err);
                },
            });
        });
    };


    const executeScan = async () => {
        try {
            init();
            // @ts-ignore
            await new Promise((resolve) => wx.ready(resolve));
            const lsh = await scanQRCode();
            formRef.current?.setFieldsValue({ ywlsh: lsh });
        } catch (err) {
            // alert(JSON.stringify(err));
        }
    };
  return (
    <>
      <Header name={'进度查询'} />
      <div style={{ padding: 10 }}>
        <City ref={cityRef} />
      </div>
      <Form
        style={{ marginTop: 10 }}
        ref={formRef}
        onFinish={onFinish}
        layout="horizontal"
        footer={
          <Grid columns={3} gap={8}>
            <Grid.Item span={2}>
              <Button
                block
                type="submit"
                color="primary"
                size="large"
                loadingText="正在加载"
                loading={loading}
              >
                查询
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Button
                block
                size="large"
                color="primary"
                fill="outline"
                onClick={()=>executeScan()}
              >
                <Space>
                  <ScanningOutline />
                  <span>扫一扫</span>
                </Space>
              </Button>
            </Grid.Item>
          </Grid>
        }
      >
        <Form.Item
          name="ywlsh"
          label="业务流水号"
          rules={[{ required: true, message: '业务流水号不能为空' }]}
        >
          <Input onChange={console.log} placeholder="请业务流水号" />
        </Form.Item>
      </Form>
      <Footer />
    </>
  );
}
