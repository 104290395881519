import React from 'react';
import { request } from '../../../util/requestPay';
import { Card, ErrorBlock, Grid, Toast } from 'antd-mobile';
import { Form, Input, Button } from 'antd-mobile';
import { BillDetail } from './pay.type';

export default function Bill() {
  const [billDetails, setBillDetails] = React.useState<BillDetail[]>([]);

  const onFinish = (values: { ywlsh: string }) => {
    billList(values.ywlsh);
  };

  const billList = (ywlsh: string) => {
    request
      .get<BillDetail[]>(`/bill/listByYwlshWx/${ywlsh}`)
      .then((data) => {
        if (data.length > 0) {
          setBillDetails(data);
        }
      })
      .catch((err) => {
        Toast.show(err.message);
      });
  };
  return (
    <>
      <Form
        onFinish={onFinish}
        name="form"
        footer={
          <Button block type="submit" color="primary" size="large">
            查询
          </Button>
        }
      >
        <Form.Item
          name="ywlsh"
          label="业务号"
          rules={[{ required: true, message: '请输入业务号' }]}
        >
          <Input placeholder="请输入业务号" />
        </Form.Item>
      </Form>
      {billDetails.length === 0 ? (
        <ErrorBlock
          title={'如果您已经缴费请耐心稍等一会'}
          description={'暂无票据'}
          status="empty"
        />
      ) : null}
      {billDetails.map((item) => {
        return (
          <Card style={{ marginTop: 20 }}>
            <Grid columns={3} gap={8}>
              <Grid.Item span={2}>
                <table width={'100%'}>
                  <tr>
                    <td style={{ width: 80 }}>交款人:</td>
                    <td style={{ textAlign: 'right' }}>{item.qlrmc}</td>
                  </tr>
                  <tr>
                    <td>金额:</td>
                    <td style={{ textAlign: 'right' }}>{item.ssje}元</td>
                  </tr>
                  <tr>
                    <td>开票时间:</td>
                    <td style={{ textAlign: 'right' }}>{item.updateTime}</td>
                  </tr>
                </table>
              </Grid.Item>
              <Grid.Item style={{ paddingTop: 26, paddingLeft: 20 }}>
                <a href={item.pictureUrl}>
                  <Button size={'small'} fill="outline" color="primary">
                    票据详情
                  </Button>
                </a>
              </Grid.Item>
            </Grid>
          </Card>
        );
      })}
    </>
  );
}
