import React, { useEffect, useState } from 'react';
import Header from '../common/Header';
import { Button, Form, Input, Switch, NoticeBar, Popup } from 'antd-mobile';
import { request } from '../util/request';
import { UserResponse } from '../common/PersonalCenter';
import { FormInstance } from 'antd-mobile/es/components/form';
import Footer from '../common/Footer';
import '../css/Yfwfzm.css';
import PdfView from '../common/PdfView';

export default function Yfwfzm() {
  const formRef = React.createRef<FormInstance>();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [user, setUser] = useState<UserResponse>();
  const [fileId, setFileId] = useState<any>();
  useEffect(() => {
    request.get<UserResponse>('/auth/user_info').then((data) => {
      setUser(data);
      // 延时设置表单值，否则会报错
      formRef.current?.setFieldsValue({
        name: data.name,
        idCard: data.idCard,
        phone: data.phone,
      });
    });
  }, []);
  const onFinish = (values: any) => {
    setLoading(true);
    request
      .post<string>('/bdc/yfwfzm', values)
      .then((data) => {
        // window.location.href = `/#/fileView/${data}`;
        setFileId(data);
        setVisible(true);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Header name={'有房无房证明'} />
      <div>
        <div
          className={user == null ? '' : 'isNone'}
          style={{ marginTop: 20, textAlign: 'center' }}
        >
          未注册，请<a href={'#/me'}>前往注册</a>
        </div>
        <div
          className={user?.faceAuth === 'no' ? '' : 'isNone'}
          style={{ marginTop: 20, textAlign: 'center' }}
        >
          未进行实名认证，请<a href={'#/me'}>前往实名</a>
        </div>
        <Form
          className={user == null || user.faceAuth === 'no' ? 'isNone' : ''}
          ref={formRef}
          layout="horizontal"
          name="form"
          onFinish={onFinish}
          footer={
            <Button
              block
              type="submit"
              color="primary"
              size="large"
              loading={loading}
            >
              查询
            </Button>
          }
        >
          <Form.Header>请仔细核对查询条件</Form.Header>
          <Form.Item
            name="name"
            label="姓名"
            rules={[{ required: true }]}
            disabled
          >
            <Input placeholder="请输入姓名" />
          </Form.Item>
          <Form.Item
            name="idCard"
            label="证件号"
            rules={[{ required: true }]}
            disabled
          >
            <Input placeholder="请输入证件号" />
          </Form.Item>
          <Form.Item
            name="phone"
            label="手机号码"
            rules={[{ required: true }]}
            disabled
          >
            <Input placeholder="请输入手机号码" />
          </Form.Item>
          {/*<Form.Item name="oldName" label="曾用名">*/}
          {/*  <Input placeholder="请输入曾用名" />*/}
          {/*</Form.Item>*/}
          {/*<Form.Item name="oldIdCard" label="曾用证件号">*/}
          {/*  <Input placeholder="请输入曾用证件号" />*/}
          {/*</Form.Item>*/}
          <Form.Item
            name="delivery"
            label="按家庭查询"
            childElementPosition="normal"
          >
            <Switch />
          </Form.Item>
          <NoticeBar
            content="请先返回首页，点击”-“家庭成员”-“添加家庭成员”"
            color="info"
          />
        </Form>
      </div>
      <Popup
        // position='right'
        visible={visible}
        showCloseButton
        onClose={() => {
          setVisible(false);
        }}
      >
        <div style={{ height: '96vh' }}>
          <PdfView fileId={fileId} />
        </div>
      </Popup>
      <Footer />
    </>
  );
}
