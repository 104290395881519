import React, { useEffect, useState, useImperativeHandle } from 'react';
import { Button, Picker, Space } from 'antd-mobile';
import { request } from '../util/request';

interface City {
  label: string;
  value: string;
}

const City = ({}, ref: any) => {
  const [cityList, setCityList] = useState<City[][]>([]);
  const [value, setValue] = useState<(string | null)[]>([
    localStorage.getItem('cityCode') || '',
  ]);
  const getData = (): string => {
    return value[0] as string;
  };
  useImperativeHandle(ref, () => ({
    getData: getData,
  }));
  useEffect(() => {
    request.get<City[]>('/sinfConfig/list').then((data) => {
      setCityList([data]);
    });
  }, []);

  return (
    <div style={{ marginTop: '6%' }}>
      <Picker
        columns={cityList}
        value={value}
        onConfirm={(e) => {
          // 将e[0]转成string类型
          const qhdm: string = e[0] as string;
          setValue([qhdm]);
        }}
      >
        {(items, { open }) => {
          return (
            <Space align="center" style={{ fontSize: 16 }}>
              <Button onClick={open}>选择区域</Button>
              {items.every((item) => item === null)
                ? '未选择'
                : items.map((item) => item?.label ?? '未选择').join(' - ')}
            </Space>
          );
        }}
      </Picker>
    </div>
  );
};
export default React.forwardRef(City);
